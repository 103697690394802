
import coreClient from '../../services/coreApi';
import { baseURL } from "../../services/baseURL.service";


interface IResolvedDisputeResponse {
  status: number;
  data?: any;
  error?: string;
}

export const useResolveDispute = () => {
  const resolveDispute = async (payload: any, slug:any) => {
    console.log(payload, 'payload====')
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const response = await coreClient.post(`${baseURL}/resolve-agent-dispute/${slug}/`, payload);
      return { status: response.status, data: response.data } as IResolvedDisputeResponse;
    } catch (error) {
      console.error('Error fetching agent details:', error);
      return { status: 500, error: 'An unknown error occurred' } as IResolvedDisputeResponse;
    }
  };

  return resolveDispute;
};
