
import coreClient from '../../services/coreApi';
import { baseURL } from "../../services/baseURL.service";


interface IPostResponse {
  status: number;
  data?: any;
  error?: string;
}

interface IPayload {
    country_code: string;
    phone_number: string;
  }

export const useAuthPostRequest = () => {
  const authPostRequest = async (payload: IPayload, endPoint: string) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const response = await coreClient.post(`${baseURL}/auth/${endPoint}/`, payload);
      return { status: response.status, data: response.data } as IPostResponse;
    } catch (error) {
      console.error('Error fetching agent details:', error);
      return { status: 500, error: 'An unknown error occurred' } as IPostResponse;
    }
  };

  return authPostRequest;
};
