import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { Search, UserPlus, ChevronsRight } from 'lucide-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { AiOutlineClose } from 'react-icons/ai'
import { useGetRequest } from '../../../../hooks/get/useGetRequest';
import { usePostRequest } from '../../../../hooks/post/usePostRequest';
import { brokerageConfirmedShowings } from '../../../../config/api-urls';
import { PlaceholderCard } from '../../../../components/skeleton';
import { NoData } from '../../../../components/placeholders';
import { SettingsBtn } from '../../../../components/button';
import CustomModal from '../../../../components/modal/CustomModal';
import logo from '../../../../assets/png/showingxchange-logo.png';
import { ItemSearch } from '../../../../components/search';
import { pageURL } from '../../../../components/navigation/pagesURL';
import { SimpleSpinner } from '../../../../components/loaders';

const ListBrokerageDisputedShowings = () => {
    const getRequest = useGetRequest();
    const postRequest = usePostRequest();
    const queryClient = useQueryClient();
    const navigateTo = useNavigate();

    const [isLoading, setIstLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedBrokers, setSelectedBrokers] = useState<any[]>([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);


    const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const { isPending, error, data } = useQuery({
        queryKey: ['brokerage-agents-confirmed-showings'],
        queryFn: ()=> getRequest(brokerageConfirmedShowings),
        retry: false
    });

    const listBrokerageAgents  = data?.data?.data || []

    console.log(listBrokerageAgents, 'listBrokerageAgents');

    const filteredQuery = Array.isArray(listBrokerageAgents)
        ? listBrokerageAgents.filter((broker: any) => {
            const result = `${broker?.email} ${broker?.phone_number}`;
            return result.toLowerCase().includes(searchQuery.toLowerCase());
        })
        : [];

    // Handle select or deselect broker
    const handleSelectBroker = (broker: any) => {
        setSelectedBrokers((prevSelected) => {
            if (prevSelected.some((item) => item.slug === broker.slug)) {
                return prevSelected.filter((item) => item.slug !== broker.slug);
            } else {
                return [...prevSelected, broker];
            }
        });
    };

    // Check if a broker is selected
    const isBrokerSelected = (broker: any) => {
        return selectedBrokers.some((item) => item.slug === broker.slug);
    };

    const handleRedirectToDetails = (slug: string) => {
        navigateTo(pageURL.brokerageAgentDetails + '/' + slug);
    };

    return (
        <div className='font-primary'>
            {isPending ? (
                <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-1 mt-10">
                    {Array.from({ length: 8 }).map((_, i) => (
                        <PlaceholderCard key={i} />
                    ))}
                </div>
            ) : (
                <>
                    {listBrokerageAgents && listBrokerageAgents.length > 0 ? (
                        <div className='bg-white rounded-md shadow-lg mx-3 p-5 mt-5'>
                            <div className='flex items-center justify-between border-b pb-3'>
                                <h3 className=' text-xsm'>Total number of confirmed showings <strong>({listBrokerageAgents.length})</strong></h3>
                            </div>
                         
                            <ItemSearch
                                searchQuery={searchQuery}
                                handleSearchQuery={handleSearchQuery}
                            />
                            <div className='h-auto my-5 overflow-y-auto'>
                                <div className='overflow-y-auto'>
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>S/N</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Phone Number</th>
                                                <th>Country</th>
                                                <th>State</th>
                                                <th>offer</th>
                                                <th>Showing Date</th>
                                                <th>Status</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredQuery.length > 0 ? (
                                                filteredQuery.map((broker: any, i: number) => (
                                                    <tr
                                                        key={i}
                                                        className={isBrokerSelected(broker) ? 'bg-whiteSmoke' : ''}
                                                    >
                                                        <td>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    className="checkbox checked:border-[#3EB489] [--chkbg:#808080] [--chkfg:#fff]"
                                                                    onChange={() => handleSelectBroker(broker)}
                                                                    checked={isBrokerSelected(broker)}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <div className="w-9 h-9 border border-slate-400 rounded-full flex justify-center items-center">
                                                                <strong>{i + 1}</strong>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{broker?.brokerage?.first_name}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{broker?.brokerage?.last_name}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{broker?.user?.email}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{broker?.user?.phone_number}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{broker?.property_country}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{broker?.property_state}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{broker?.currency} {broker?.compensation_offer}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{broker?.requested_showing_date}</span>
                                                        </td>
                                                        <td>
                                                            <span className={`badge badge-${broker?.activated===false ? 'warning' : 'ghost'} badge-sm`}>{broker?.is_status}</span>
                                                        </td>
                                                        {/* <td>
                                                            <button
                                                                className="flex items-center gap-2 bg-[#000]/50 font-normal rounded-md px-2 py-1 text-white"
                                                                onClick={() => handleRedirectToDetails(broker?.slug)}
                                                            >
                                                                Details <ChevronsRight size={20} />
                                                            </button>
                                                        </td> */}
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={8} className="text-center">
                                                        No result found for <strong>{searchQuery}</strong>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='h-screen flex flex-col gap-5 justify-center items-center'>
                            <NoData icon={<Search size={30} />}>
                                <h3 className='text-normal'>No Confirmed Showings Found</h3>
                            </NoData>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ListBrokerageDisputedShowings;
