
import coreClient from '../../services/coreApi';
import { baseURL } from "../../services/baseURL.service";

interface IListResolvedResponse {
  status: number;
  data?: any;
  error?: string;
}

export const useListResolvedDisputes = () => {
  const listResolvedDisputes = async () => {
    try {
      const response = await coreClient.get(`${baseURL}/list-completed-resolved-disputes/`);
      return { status: response.status, data: response.data } as IListResolvedResponse;
    } catch (error) {
      throw error
    }
  };

  return listResolvedDisputes;
};
