
import React, { useState} from 'react';
import { Formik, Form } from "formik";
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { Search, UserPlus } from 'lucide-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { AiOutlineClose } from 'react-icons/ai'
import { useGetRequest } from '../../../hooks/get/useGetRequest';
import { useAuthPostRequest } from '../../../hooks/post/useAuthPostRequest';
import { getBrokersNoProfilePath } from '../../../config/api-urls';
import { PlaceholderCard } from '../../../components/skeleton';
import { NoData } from '../../../components/placeholders';
import { SettingsBtn } from '../../../components/button';
import CustomModal from '../../../components/modal/CustomModal';
import { SimpleSpinner } from '../../../components/loaders';
import { TextInput } from '../../../components/forms/inputs';
import { PhoneInput } from '../../../components/forms/inputs';
import { Button } from '../../../components/button';
import logo from '../../../assets/png/showingxchange-logo.png';
import { countriesFlagPhoneExts } from '../../../utils/countriesFlagPhoneExts';
import { CreateOperationOrBillingFormSchema } from '../../../utils/yupSchemas';
import { adminSignsBrokerPath } from '../../../config/api-urls';
import { ItemSearch } from '../../../components/search';
import { CreateBrokerProfileForm } from '../../../components/forms/user-forms';

const ListBrokersNoProfile = () => {
    const getRequest = useGetRequest();
    const authPostRequest = useAuthPostRequest();
    const queryClient = useQueryClient();

    const [selectedProfile, setSelectedProfile] = useState<any>({});
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchCountryQuery, setCountrySearchQuery] = useState<string>('');
    const [showCountrySearch, setShowCountrySearch] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState({name: 'United States', flag: '🇺🇸', code: 'US', dial_code: '+1'});

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const [isCreateProfileModalOpen, setIsCreateProfileModalOpen] = useState(false);
    const handleOpenCreateProfileModal = () => setIsCreateProfileModalOpen(true);
    const handleCloseCreateProfileModal = () => setIsCreateProfileModalOpen(false);



    const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };
    const handleSelectCountry = (country:any)=>{
        setSelectedCountry(country)
      }
    
      const handleToggleCountrySearch = ()=>{
        setShowCountrySearch(!showCountrySearch) 
      }
    
    
      const handleCountrySearchQuery = (e:any)=>{
        setCountrySearchQuery(e.target.value);
        }

        const filterdCountries = countriesFlagPhoneExts && countriesFlagPhoneExts.filter((country)=>{
            const result = `${country?.name} ${country?.dial_code}`;
            return result.toLocaleLowerCase().includes(searchCountryQuery.toLocaleLowerCase()) 
        })

    const { isPending, error, data } = useQuery({
        queryKey: ['brokers-no-profile'],
        queryFn: ()=> getRequest(getBrokersNoProfilePath),
         // staleTime: 10000,
        // enabled: false,
        retry: false
    });

    const listBrokersNoprofile  = data?.data?.data || []

    const filteredQuery = Array.isArray(listBrokersNoprofile)
    ? listBrokersNoprofile.filter((broker: any) => {
        const result = `${broker?.email} ${broker?.phone_number}`;
        return result.toLowerCase().includes(searchQuery.toLowerCase());
    })
    : [];

    

    const handleSelectProfile = (brokerId: {})=>{
        setSelectedProfile(brokerId);
        handleOpenCreateProfileModal();
    }
    
  return (
    <div className='font-primary'>
        {
            isPending ? (
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
                    {Array.from({ length: 8 }).map((_, i) => (
                        <PlaceholderCard key={i} />
                    ))}
                </div>
            ):(
                <>
                    {listBrokersNoprofile && listBrokersNoprofile.length > 0 ? (
                    <div className='bg-white rounded-md shadow-lg mx-3 p-5 mt-5'>
                    <div className='flex justify-end'>
                        <SettingsBtn
                            isBorder={false}
                            bgColor="bg-customPrimary"
                            textColor="text-white"
                            width="md:w-2/12"
                            onClick={handleOpenModal}
                        >
                        Create New Broker
                        </SettingsBtn>
                    </div>
                    <div className='flex items-center justify-between border-b pb-3'>
                    <h3 className=' text-xsm'>Number of Brokers without Profile <strong>({listBrokersNoprofile && listBrokersNoprofile.length})</strong></h3>
                    </div>
                 
                    <ItemSearch
                    searchQuery={searchQuery}
                    handleSearchQuery={handleSearchQuery}
                    />
                    <div className='h-auto my-5 overflow-y-auto'>
                    {   
                        <div className='overflow-y-auto'>
                            <table className="table table-sm">
                            <thead>
                                <tr>
                                  <th></th>
                                <th>S/N</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Action</th>
                                </tr>
                            </thead>
                            <>
                              {
                                filteredQuery.length > 0 
                                ? (
                                <>
                                  {
                                    filteredQuery && filteredQuery?.map((broker: any, i: number)=>(
                                    <>
                                      <tbody key={i}>
                                      {/* row 1 */}
                                      <tr>
                                      <th>
                                          <label>
                                          <input type="checkbox" 
                                          className="checkbox checked:border-[#3EB489 [--chkbg:#808080] [--chkfg:#fff]" />
                                          </label>
                                      </th>
                                      <td>
                                          <div className=" w-9 h-9 border border-slate-400 rounded-full flex justify-center items-center">
                                                  <strong>{i +1 }</strong>
                                          </div>
                                      </td>
                                      <td>
                                          <span className="badge badge-ghost badge-sm">{broker?.email}</span>
                                      </td>
                                      <td>
                                          <span className="badge badge-ghost badge-sm">{broker?.phone_number}</span>
                                      </td>
                                      <th>
                                          <button className="flex items-center gap-2 bg-[#000]/50 font-normal rounded-md px-2 py-1 text-white" onClick={()=>handleSelectProfile(broker)}><UserPlus size={20}/> Create profile</button>
                                      </th>
                                      </tr>
                                  </tbody>
                                    </>
                                    ))
                                  }
                                </>)
                                : 
                                (<div  className='h-full flex justify-center items-center mt-10'>
                                  <h3>No result found for <strong>{searchQuery}</strong> </h3>
                                </div>)
                              }
                            </>
                            </table>
                        </div>
                        
                    }
                    </div>
                </div>
                    ) : (
                        <div className='h-screen flex flex-col gap-5 justify-center items-center'>
                            <NoData icon={<Search size={30} />}>
                                <h3 className='text-normal'>No Brokers Found</h3>
                            </NoData>
                            <SettingsBtn
                                isBorder={false}
                                bgColor="bg-customPrimary"
                                textColor="text-white"
                                width="md:w-2/12"
                                onClick={handleOpenModal}
                            >
                            Create Broker
                            </SettingsBtn>
                        </div>
                    )}
                </>
            )
        }

        <CustomModal
            width="w-[300px] md:w-5/12"
            icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
            iconRemove={<AiOutlineClose onClick={handleCloseModal} className="w-6 h-6 text-[#667085]" />}
            title="Create Broker"
            description=""
            bgColor="bg-white"
            textColor="text-black"
            closeModal={handleCloseModal}
            isModalOpen={isModalOpen}
        >
            <Formik
            initialValues={{ email: "", phone_number:"", password: "", }}
            validationSchema={CreateOperationOrBillingFormSchema}
         
            onSubmit={async (values, { setSubmitting }) => {
              const payload = {
                email: values.email,
                password: values.password,
                phone_number: values.phone_number,
                country_code: selectedCountry.dial_code,
              }
              try {
                const { status, data, error } = await authPostRequest(payload, adminSignsBrokerPath);
                console.log(status, 'status')
                console.log(data)
                if (status === 201) {
                  toast.success(data?.message);
                  queryClient.invalidateQueries({ queryKey: ['brokers-no-profile'] });
                  handleCloseModal();
                }
              } catch (error:any) {
                console.log(error, 'err****')
                if (error.response && error.response.data) {
                    console.log(error, 'err')
                    toast.error(`${error.response.data.message}`);
                  } else if (error.request) {
                      toast.error('No response from server. Please try again later.');
                  } else {
                      toast.error(`Error: ${error.data.message}`);
                  }
              }
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
              <div>
                <TextInput
                  label='user email'
                  name='email'
                  icon={undefined}
                  type='email'
                  placeholder='email'
                />
                <>
                <h3 className='text-sm font-normal mb-1 ml-1'>Enter User Phone Number</h3>
                  <div className='flex items-center relative'>
                    <div className='md:w-3/12'>
                        <div className='p-3 flex items-center gap-3 border cursor-pointer text-sm' onClick={handleToggleCountrySearch}>
                            <h3>{selectedCountry.flag}</h3>
                            <span>{selectedCountry.dial_code}</span>
                        </div>
                        <div className=''>
                            {
                                showCountrySearch && (
                                    <div className='w-full h-64 p-5 shadow-lg mt-10  absolute top-10 left-0 bg-white z-10 overflow-y-scroll'>
                                        <input 
                                            type="search"
                                            className='w-full rounded-md px-5 py-3 mt-2 outline-none border'
                                            placeholder='search country code'
                                            value={searchCountryQuery}
                                            onChange={handleCountrySearchQuery}
                                        />
                                        <ul className=''>
                                            {
                                                filterdCountries.map((country, i)=>(
                                                    <li className='flex items-center gap-3 border-b py-2 cursor-pointer' key={i} onClick={()=>{
                                                        handleSelectCountry(country)
                                                        handleToggleCountrySearch()
                                                    }}>
                                                        <span>{country.flag}</span><h3>{country.name}({country.dial_code})</h3>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                     <PhoneInput
                      name='phone_number'
                      icon={undefined}
                      type='number'
                      placeholder='user phone number'
                    />
                  </div>
                </>
                <div className='relative'>
                  <TextInput
                    label='user password'
                    name='password'
                    icon={undefined}
                    type='password'
                    placeholder='password'
                  />
                </div>
              </div>
              <Button 
                disabled={isSubmitting}
                isBorder={false}
                >{
                isSubmitting ? <div className='flex justify-center items-center'><SimpleSpinner size={'lg'}/></div> : <span>Create Broker Account</span>
                }
                
              </Button>
            </Form>
            )}
          </Formik>
        </CustomModal>

        {/* create broker profile */}
        <CustomModal
            width="w-[300px] md:w-8/12"
            icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
            iconRemove={<AiOutlineClose onClick={handleCloseCreateProfileModal} className="w-6 h-6 text-[#667085]" />}
            title={`Create Broker Profile for ${selectedProfile?.email || ''}`}
            description="You are about to create a profile for this broker"
            bgColor="bg-white"
            textColor="text-black"
            closeModal={handleCloseCreateProfileModal}
            isModalOpen={isCreateProfileModalOpen}
        >
            <CreateBrokerProfileForm 
                handleCloseCreateProfileModal={handleCloseCreateProfileModal}
                selectedProfile={selectedProfile}
            />
        </CustomModal>
    </div>
  )
}

export default ListBrokersNoProfile