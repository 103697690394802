import React, { Children } from 'react';

interface ITitleTextProps {
  children?: string;
  textColor?: string;
  fontSize?: string;
}
const TitleText = ({ children, textColor, fontSize }: ITitleTextProps) => {
  const textClasses = `${textColor} ${fontSize} capitalize font-bold`;
  return <h3 className={textClasses}>{children}</h3>;
};

export default TitleText;
