import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle, ArrowRight } from "lucide-react"
import { SettingsBtn } from '../button'
import { pageURL } from '../navigation/pagesURL';

const PaymentSuccessCard = ({ message }: { message: string }) => {

    const navigateTo = useNavigate();
    const handleRedirect = ()=>{
        navigateTo(pageURL.authDashboard)
    }
  return (
    <div className="min-h-screen bg-whiteSmoke flex items-center justify-center p-4 font-primary">
      <div className="w-full max-w-md bg-white p-10 rounded-md">
        <div className="pt-6">
          <div className="flex flex-col items-center text-center">
            <div className="relative mb-4">
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-16 h-16 border-4 border-green-200 rounded-full animate-ping" />
              </div>
              <CheckCircle className="w-16 h-16 text-green-500 relative z-10" />
            </div>
            <h1 className="text-2xl font-bold text-gray-900 mb-2">Payment Successful!</h1>
            <p className="text-gray-600 mb-6">{message}</p>
            <div className="bg-gray-50 rounded-lg p-4 w-full mb-6">
              {/* <div className="flex justify-between items-center mb-2">
                <span className="text-gray-600">Amount Paid:</span>
                <span className="font-semibold">$99.99</span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-600">Transaction ID:</span>
                <span className="font-semibold">TRX123456789</span>
              </div> */}
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Date:</span>
                <span className="font-semibold">{new Date().toLocaleDateString()}</span>
              </div>
            </div>
            <SettingsBtn
                isBorder={false}
                bgColor="bg-customPrimary"
                textColor="text-white"
                width="md:w-full"
                onClick={handleRedirect}
            >
           Return to Dashboard
           <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
            </SettingsBtn>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentSuccessCard