import { PaymentSuccessCard } from '../../components/cards'

const ConnectAccountSuccess = () => {
  return (
    <PaymentSuccessCard
    message='payment was successful'
  />
  )
}

export default ConnectAccountSuccess