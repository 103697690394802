import { FaTimesCircle } from 'react-icons/fa';
import { X } from 'lucide-react';
import { motion } from 'framer-motion';
interface ModalDialogProps {
    modalOpen:boolean,
    modalClose:any,
    children:React.ReactNode,
}

const ModalDialog = ({ children, modalOpen, modalClose }: ModalDialogProps) => {
   
  return (
    <>
        {
            modalOpen && (
                <div className='w-full h-screen flex justify-center items-center bg-customPrimary/60 absolute top-0 left-0'>
                    <motion.div 
                    initial={{ 
                        x: -100, 
                        opacity: 0, 
                        // scale: 0.8 
                    }} 
                    animate={{ x: 0, opacity: 1, scale: 1 }} 
                    transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
                    className='md:h-8/12 md:w-5/12 bg-white shadow-md rounded-md relative'>
                        <span onClick={modalClose} className="cursor-pointer absolute right-6 top-5 text-slate-500"><X /></span>
                        <div className='p-5'>
                            { children }
                        </div>
                    </motion.div>
                </div>
            )
        }
    </>
  )
}

export default ModalDialog