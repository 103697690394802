import React, { useState, useEffect, Suspense } from 'react';
import { toast } from 'sonner';
import { Search } from 'lucide-react';
import { AiOutlineClose } from 'react-icons/ai'
import { useListAgents } from '../../hooks/get/useListAgents';
import { useAssignAgent } from '../../hooks/post/useAssignAgent';
import { AgentApplicationListCard } from '../../components/cards';
import { PlaceholderCard } from '../../components/skeleton';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/SelectFilter"
import { ItemSearch } from '../../components/search';
import { NoData } from '../../components/placeholders';
import CustomModal from '../../components/modal/CustomModal';
import SettingsBtn from '../../components/button/SettingsBtn';
import { SimpleSpinner } from '../../components/loaders';
import logo from '../../assets/png/showingxchange-logo.png';



const OperationDashboard = () => {
    const [loading, setLoading] = useState<Boolean>(false);
    const [agents, setAgents] = useState([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [loadingAssignAgent, setLoadingAssignAgent] = useState(false);
    const [activeSlug, setActiveSlug] = useState<string>('');
    const listAgents = useListAgents();
    const assignAgent = useAssignAgent();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleSearchQuery = (e:any)=>{
        setSearchQuery(e.target.value);
    }


    const filteredAgents = agents && agents.filter((agent:any)=>{
        const result = `${agent?.first_name} ${agent?.gender} ${agent?.last_name} ${agent?.agent_country}`;
        return result.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) 
    })

    async function renderAgentApplications(){
        try {
          const { status, data  } = await listAgents('list-agent-applications');
          if(status===200){
            setAgents(data?.data)
          }
        } catch (error:any) {
          if (error.response && error.response.data && error.response.data) {
            toast.error(`${error.response.data.message}`);
          } else if (error.request) {
              toast.error('No response from server. Please try again later.');
          } else {
              toast.error(`${error.message}`);
          }
        }
    }

    const handleAssignAgent = async()=>{
        try {
          setLoadingAssignAgent(true);
          const { status, data } = await assignAgent({ slug:activeSlug }, { endpoint:'assign-agent-application' });
          if (status === 200) {
            toast.success(`${data.message}`);
            handleCloseModal();
            renderAgentApplications();
          } 
        } catch (error:any) {
          if (error.response && error.response.data && error.response.data) {
            toast.error(`${error.response.data.message}`);
          } else if (error.request) {
              toast.error('No response from server. Please try again later.');
          } else {
              toast.error(`${error.message}`);
          }
        }finally{
          setLoadingAssignAgent(false)
        }
      }
  
      useEffect(()=>{
       async function getAgents(){
         try {
           setLoading(true);
           const { status, data  } = await listAgents('list-agent-applications');
           if(status===200){
            setAgents(data?.data)
           }
         } catch (error:any) {
           if (error.response && error.response.data && error.response.data) {
               toast.error(`${error.response.data.message}`);
             } else if (error.request) {
                 toast.error('No response from server. Please try again later.');
             } else {
                 toast.error(`${error.message}`);
             }
         }finally{
           setLoading(false)
         }
       }
       getAgents();
      },[]);

  return (
    <div className='font-primary mt-10'>
      {loading ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
          {Array.from({ length: 8 }).map((_, i) => (
            <PlaceholderCard key={i} />
          ))}
        </div>
      ) : (
        <>
        {
          agents.length > 0 ? (
          <>
             <div className='bg-white rounded-md shadow-lg mx-3 p-5 mt-5'>
              <div className='flex items-center justify-between border-b pb-3'>
                <h3 className='font-bold text-xsm'>
                  Total Agent Applications ({agents.length})
                </h3>
                <div>
                  <Select>
                    <SelectTrigger className="md:w-[180px]">
                      <SelectValue placeholder="All" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="approved">Approved</SelectItem>
                      <SelectItem value="pending">Pending</SelectItem>
                      <SelectItem value="rejected">Rejected</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <ItemSearch searchQuery={searchQuery} handleSearchQuery={handleSearchQuery} />
              <div className='h-72 my-5 overflow-y-auto'>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Job</th>
                      <th>Basic Information</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                    {filteredAgents.map((application, i) => (
                      <AgentApplicationListCard 
                        handleToggleAssignAgentModal={handleOpenModal}
                        setActiveSlug={setActiveSlug}
                        application={application} 
                        i={i} 
                        key={i}
                        userType='agent'
                      />
                    ))}
                </table>
              </div>
            </div>
          </>) : 
          (<>
           <div className='h-screen flex flex-col justify-center items-center gap-10'>
              <NoData icon={<Search size={50} />}>
                <h3 className='text-normal'>No Applications Found</h3>
              </NoData>
            </div>
          </>)
        }
        </>
      )
    }
    <CustomModal
         width="w-[300px] md:w-5/12"
         icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
         iconRemove={<AiOutlineClose onClick={handleCloseModal} className="w-6 h-6 text-[#667085]" />}
         title="Assign Agent"
         description="Are you sure you want to assign this agent to yourself?"
         bgColor="bg-white"
         textColor="text-black"
         closeModal={handleCloseModal}
         isModalOpen={isModalOpen}
      >
       
        <div className='flex justify-between items-center border-t py-5'>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-whiteSmoke"
            textColor="text-[#000]"
            width="md:w-3/12"
            onClick={handleCloseModal}
        >
          Cancel
        </SettingsBtn>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-customPrimary"
            textColor="text-white"
            width="md:w-3/12"
            onClick={handleAssignAgent}
        >          
          { loadingAssignAgent ? <SimpleSpinner size={'sm'}/> : 'Assign Agent' }
        </SettingsBtn>
        </div>

      </CustomModal>
    </div>
  )
}

export default OperationDashboard