
import coreClient from '../../services/coreApi';
import { baseURL } from "../../services/baseURL.service";

interface IDisputeDetailsResponse {
  status: number;
  data?: any;
  error?: string;
}

export const useGetDisputeDetails = () => {
  const getDisputeDetails = async (endPoint: string, slug: any) => {
    try {
      const response = await coreClient.get(`${baseURL}/${endPoint}/${slug}/`);
      return { status: response.status, data: response.data } as IDisputeDetailsResponse;
    } catch (error) {
      console.error('Error fetching agent details:', error);
      return { status: 500, error: 'An unknown error occurred' } as IDisputeDetailsResponse;
    }
  };

  return getDisputeDetails;
};
