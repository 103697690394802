
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useGetRequestDetails } from '../../../hooks/get/useGetRequestDetails';
import { brokerageDetailedShowing } from '../../../config/api-urls';

const BrokerageAgentShowingsDetails = () => {
    const { slug } = useParams();
    const getRequestDetails = useGetRequestDetails();
    console.log(slug, '======slug');

    const { isPending, error, data } = useQuery({
        queryKey: ['broker-agent-showings-details'],
        queryFn: ()=> getRequestDetails(brokerageDetailedShowing, slug),
        retry: false
      });
  
      const agentShowingsDetails = data?.data?.data || {};

      console.log(agentShowingsDetails, 'agentShowingsDetails res')
  return (
    <div>BrokerageAgentShowingsDetails</div>
  )
}

export default BrokerageAgentShowingsDetails