
import coreClient from '../../services/coreApi';
import { baseURL } from "../../services/baseURL.service";


interface IPostResponse {
  status: number;
  data?: any;
  error?: string;
}

// interface IPayload {
//     country_code: string;
//     phone_number: string;
//   }

export const usePostRequest = () => {
  const postRequest = async (endPoint: string, payload: any) => {
    console.log(payload, 'payload====')
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const response = await coreClient.post(`${baseURL}/${endPoint}/`, payload);
      return { status: response.status, data: response.data } as IPostResponse;
    } catch (error) {
      throw error
    }
  };

  return postRequest;
};
