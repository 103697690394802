import React, { useState, useEffect, Suspense } from 'react';
import { toast } from 'sonner';
import { Search } from 'lucide-react';
import { useListAssignedAgents } from '../../../hooks/get/useListAssignedAgents';
import { AssignedAgentsCard } from '../../../components/cards';
import { PlaceholderCard } from '../../../components/skeleton';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/SelectFilter"
import { ItemSearch } from '../../../components/search';
import { NoData } from '../../../components/placeholders';



const ListAssignedAgents = () => {
    const [loading, setLoading] = useState<Boolean>(false);
    const [assignedAgents, setAssignedAgents] = useState<[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const listAssignedAgents = useListAssignedAgents();

    const handleSearchQuery = (e:any)=>{
        setSearchQuery(e.target.value);
    }


    const filteredAgents = assignedAgents && assignedAgents.filter((agent:any)=>{
        const result = `${agent?.first_name} ${agent?.gender} ${agent?.last_name} ${agent?.agent_country}`;
        return result.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) 
    })
  
      useEffect(()=>{
       async function getAssignedAgents(){
         try {
           setLoading(true);
           const { status, data  } = await listAssignedAgents('list-assigned-applications');
           console.log(data.data, '===========');
           if(status===200){
               setAssignedAgents(data?.data)
           }
         } catch (error:any) {
           if (error.response && error.response.data && error.response.data) {
               toast.error(`${error.response.data.message}`);
             } else if (error.request) {
                 toast.error('No response from server. Please try again later.');
             } else {
                 toast.error(`${error.message}`);
             }
         }finally{
           setLoading(false)
         }
       }
       getAssignedAgents();
      },[]);

  return (
    <div className='font-primary mt-10'>
      {loading ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
          {Array.from({ length: 8 }).map((_, i) => (
            <PlaceholderCard key={i} />
          ))}
        </div>
      ) : (
        <>
        {
          assignedAgents.length > 0 ? (
          <>
             <div className='bg-white rounded-md shadow-lg mx-3 p-5 mt-5'>
              <div className='flex items-center justify-between border-b pb-3'>
                <h3 className='font-bold text-xsm'>
                  Assigned Applications ({assignedAgents.length})
                </h3>
                <div>
                  <Select>
                    <SelectTrigger className="md:w-[180px]">
                      <SelectValue placeholder="All" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="approved">Approved</SelectItem>
                      <SelectItem value="pending">Pending</SelectItem>
                      <SelectItem value="rejected">Rejected</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <ItemSearch searchQuery={searchQuery} handleSearchQuery={handleSearchQuery} />
              <div className='h-72 my-5 overflow-y-auto'>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Job</th>
                      <th>Basic Information</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredAgents.map((application, i) => (
                      <AssignedAgentsCard
                        application={application}
                        i={i}
                        key={i}
                        handleToggleAssignAgentModal={() => {}}
                        setActiveSlug={() => {}}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>) : 
          (<>
           <div className='h-screen flex flex-col justify-center items-center gap-10'>
              <NoData icon={<Search size={50} />}>
                <h3 className='text-normal'>No Assigned <br /> Applications Found</h3>
              </NoData>
            </div>
          </>)
        }
        </>
      )
    }
    </div>
  )
}

export default ListAssignedAgents