import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LineChartHero } from '../../../components/graphs';
import { pageURL } from '../../../components/navigation/pagesURL';
import { HandCoins, Users, Handshake, ListTodo } from 'lucide-react';

const StatsGraphs = ({ agents }: any) => {

  return (
    <div className='flex flex-col md:flex-row gap-x-5 px-3 mt-3'>
        <div className='md:w-1/2 shadow-lg px-3 rounded-md mt-10 md:mt-0'>
          <div className="grid grid-cols-2 md:grid-cols-2 gap-4 justify-items-stretch content-stretch">
            <Link to={pageURL.operationDashboard}>
              <div className='border p-5 md:p-7 flex flex-col items-center justify-center hover:border hover:border-customPrimary duration-500'>
                <h3 className='text-xsm font-normal text-center'>Operations</h3>
                {/* <h3 className='text-sm font-normal text-customSecondary'>{agents?.length || 0}</h3> */}
                <span className='text-sm font-normal text-customSecondary'><Users/></span>
              </div>
            </Link>
            <Link to={pageURL.billingDashboard}>
              <div className='border p-5 md:p-7 flex flex-col items-center justify-center hover:border hover:border-customPrimary duration-500'>
                <h3 className='text-xsm font-normal text-center'>Billings</h3>
                <span className='text-sm font-normal text-customSecondary'><HandCoins/></span>
              </div>
            </Link>
            <Link to={pageURL.managingBrokerDashboard}>
              <div className='border p-5 md:p-7 flex flex-col items-center justify-center hover:border hover:border-customPrimary duration-500'>
                <h3 className='text-xsm font-normal text-center'>managing Broker</h3>
                <span className='text-sm font-normal text-customSecondary'><Handshake/></span>
              </div>
            </Link>
            <Link to={pageURL.listAgentApplications}>
              <div className='border p-5 md:p-7 flex flex-col items-center justify-center hover:border hover:border-customPrimary duration-500'>
                <h3 className='text-xsm font-normal text-center'>Agent Applications</h3>
                <span className='text-sm font-normal text-customSecondary'><ListTodo/></span>
              </div>
            </Link>
          </div>
        </div>
        <div className='md:w-2/3 shadow-lg px-3 rounded-md'>
            <LineChartHero />
        </div>
    </div>
  )
}

export default StatsGraphs