import React from 'react'

const PrivacyPolicy = () => {
  return (
    // <div className='h-screen w-3/4 m-auto grid grid-cols-1 border'>
    //     <iframe 
    //         src="https://showingxchange-public-access.s3.us-east-2.amazonaws.com/docs/privacypolicy.html"
    //         width="100%" 
    //         height="100%"
    //         title='legal'
    //     ></iframe>
    // </div>
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg font-primary my-5">
      <h1 className="text-sm font-bold mb-4">Privacy Policy - ShowingXchange</h1>
      <p className="mb-4"><strong>Effective Date:</strong> 8/9/2024</p>
      
      <h2 className="text-sm font-bold mb-2">Introduction</h2>
      <p className="mb-4">
        Welcome to ShowingXchange ("we", "our", "us"). We are committed to protecting your privacy. This
        Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use
        our mobile application ("App") and services. Please read this policy carefully to understand our views
        and practices regarding your personal data and how we will treat it.
      </p>
      
      <h2 className="text-sm font-bold mb-2">1. Information We Collect</h2>
      <h3 className="text-sm font-bold mb-2">1.1 Personal Information</h3>
      <p className="mb-2">
        When you register for an account, use our services, or contact us, we may collect the following
        personal information:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>Identity Information:</strong> Name, username, password, and other identifiers.</li>
        <li><strong>Contact Information:</strong> Email address, phone number, and home address.</li>
        <li><strong>Financial Information:</strong> Payment card details and other financial information necessary to process transactions.</li>
        <li><strong>Profile Information:</strong> Preferences, feedback, and survey responses.</li>
        <li><strong>Marketing and Communications Information:</strong> Your preferences in receiving marketing from us and your communication preferences.</li>
      </ul>
      
      <h3 className="text-sm font-bold mb-2">1.2 Usage Data</h3>
      <p className="mb-2">We may collect information about your interactions with the App, including:</p>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>Device Information:</strong> IP address, browser type, version, time zone setting, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this App.</li>
        <li><strong>App Usage Data:</strong> Features used, time spent on the app, pages viewed, navigation paths, and other actions you take on the app.</li>
        <li><strong>Location Data:</strong> We may collect information about your location, with your consent, to provide location-based services.</li>
      </ul>
      
      <h3 className="text-sm font-bold mb-2">1.3 Cookies and Tracking Technologies</h3>
      <p className="mb-4">
        We use cookies, web beacons, pixels, and similar tracking technologies to collect information about
        your activity on our App. This helps us understand usage patterns and improve our services.
      </p>
      
      <h3 className="text-sm font-bold mb-2">1.4 Third-Party Data</h3>
      <p className="mb-4">
        We may receive information about you from third parties, such as social media platforms, marketing
        partners, and publicly available sources. This information may include your social media profile
        information, marketing preferences, and additional information about your interests and demographic
        characteristics.
      </p>
      
      <h2 className="text-sm font-bold mb-2">2. How We Use Your Information</h2>
      <p className="mb-2">We use the information we collect for the following purposes:</p>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>To Provide and Manage Services:</strong> To deliver the services you request, manage your account, process transactions, and provide customer support.</li>
        <li><strong>To Improve Our Services:</strong> To understand how users interact with our App, identify trends, and improve our services and App functionality.</li>
        <li><strong>To Personalize Your Experience:</strong> To tailor the content and features you see based on your preferences and interactions.</li>
        <li><strong>To Communicate with You:</strong> To send you updates, security alerts, and administrative messages, and to respond to your inquiries and feedback.</li>
        <li><strong>To Market and Promote Our Services:</strong> To send you promotional materials, newsletters, and offers (with your consent).</li>
        <li><strong>To Ensure Security and Compliance:</strong> To protect against fraud, unauthorized transactions, claims, and other liabilities, and to comply with legal obligations.</li>
        <li><strong>To Conduct Analytics and Research:</strong> To conduct research, analysis, and surveys to improve our services and understand our users' needs and preferences.</li>
      </ul>
      
      <h2 className="text-sm font-bold mb-2">3. Sharing Your Information</h2>
      <p className="mb-2">We may share your information with:</p>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>Service Providers:</strong> We may share your information with third-party vendors, service providers, contractors, or agents who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
        <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
        <li><strong>Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy.</li>
        <li><strong>Business Partners:</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
        <li><strong>With Your Consent:</strong> We may disclose your personal information for any other purpose with your consent.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
      </ul>

      <h2 className="text-sm font-bold mb-2">4. Security of Your Information</h2>
      <p className="mb-4">
        We use administrative, technical, and physical security measures to protect your personal
        information. These measures include encryption, firewalls, secure servers, and access controls.
        While we have taken reasonable steps to secure the personal information you provide to us, please
        be aware that no security measures are perfect or impenetrable.
      </p>

      <h2 className="text-sm font-bold mb-2">5. Your Data Protection Rights</h2>
      <p className="mb-2">Depending on your location, you may have the following rights regarding your personal information:</p>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>The Right to Access:</strong> You have the right to request copies of your personal information.</li>
        <li><strong>The Right to Rectification:</strong> You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
        <li><strong>The Right to Erasure:</strong> You have the right to request that we erase your personal information, under certain conditions.</li>
        <li><strong>The Right to Restrict Processing:</strong> You have the right to request that we restrict the processing of your personal information, under certain conditions.</li>
        <li><strong>The Right to Object to Processing:</strong> You have the right to object to our processing of your personal information, under certain conditions.</li>
        <li><strong>The Right to Data Portability:</strong> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
      </ul>
      <p className="mb-4">
        To exercise any of these rights, please contact us at <a href="mailto:support@showingxchange.com" className="text-blue-600 hover:underline">support@showingxchange.com</a>.
      </p>
      <p className="mb-4">
        <strong>Account Deletions:</strong> In order to request us to delete your account, please email us at <a href="mailto:support@showingxchange.com" className="text-blue-600 hover:underline">support@showingxchange.com</a>.
      </p>

      <h2 className="text-sm font-bold mb-2">6. Retention of Your Information</h2>
      <p className="mb-4">
        We will retain your personal information only for as long as is necessary for the purposes set out in
        this Privacy Policy, and to the extent necessary to comply with our legal obligations, resolve
        disputes, and enforce our policies. When we no longer need your personal information, we will
        securely delete or anonymize it.
      </p>

      <h2 className="text-sm font-bold mb-2">7. International Data Transfers</h2>
      <p className="mb-4">
        Your information may be transferred to, and maintained on, computers located outside of your state,
        province, country, or other governmental jurisdiction where the data protection laws may differ from
        those of your jurisdiction. By using our App, you consent to the transfer of your information to the
        United States and other jurisdictions.
      </p>

      <h2 className="text-sm font-bold mb-2">8. Children's Privacy</h2>
      <p className="mb-4">
        Our App is not intended for use by children under the age of 13. We do not knowingly collect
        personal information from children under 13. If we become aware that we have collected personal
        information from a child under age 13, we will take steps to delete such information from our records.
      </p>

      <h2 className="text-sm font-bold mb-2">9. Changes to This Privacy Policy</h2>
      <p className="mb-4">
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting
        the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for
        any changes. Changes to this Privacy Policy are effective when they are posted on this page.
      </p>

      <h2 className="text-sm font-bold mb-2">10. Third-Party Links</h2>
      <p className="mb-4">
      Our App may contain links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our App, we encourage you to read the privacy policy of every website you visit
      </p>

      <h2 className="text-sm font-bold mb-2">11. Your California Privacy Rights</h2>
      <p className="mb-4">
      Your California Privacy Rights
      <p className="mb-4">
      If you are a California resident, you have specific rights under the California Consumer Privacy Act (CCPA). These rights include:
      </p>
      </p>
      <li><strong>The Right to Know:</strong> You can request information about the categories and specific pieces of personal information we have collected about you, the sources from which we collect the information, the purpose for which we use it, and the third parties with whom we share it.</li>
        <li><strong>The Right to Delete:</strong> You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
        <li><strong>The Right to Delete:</strong> You can request that we delete any personal information we have collected from you, subject to certain exceptions.</li>
        <li><strong>The Right to Opt-Out:</strong> You have the right to opt out of the sale of your personal information. Note that we do not sell your personal information to third parties.</li>
        <li><strong>The Right to Non-Discrimination:</strong> You have the right not to receive discriminatory treatment for exercising your CCPA rights.

    To exercise any of these rights, please contact us at support@showingxchange,com. We will verify your request and respond within the timeframes required by law.</li>
    <h2 className="text-sm font-bold mb-2">12. Contact Us</h2>
    <p>
      If you have any questions about this Privacy Policy, or if you wish to exercise your rights or make a complaint, please contact us at:

      ShowingXchange

      10006 Cross Creek Blvd Ste 148, Tampa, Florida, 33647 <br />

      <a href="mailto:support@showingxchange.com" className="text-blue-600 hover:underline">support@showingxchange.com</a>. <br />

      <strong>904-569-7483</strong> <br />

      13. Consent

      By using our App, you consent to our Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use our App. Your continued use of the App following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.
    </p>
    <h2 className="text-sm font-bold mb-2"> 13. Consent</h2>
    <p>By using our App, you consent to our Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use our App. Your continued use of the App following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.</p>
  </div>
    
  )
}

export default PrivacyPolicy