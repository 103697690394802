import React from 'react';
import tokenService from '../../services/token.service';
import { 
  AdminDashboard,
  BillingDashboard,
  OperationDashboard,
  ManagingBrokerDashboard
} from './'
import { DashboardHeader } from './header';

const AuthDashboard = () => {
  const { user } = tokenService.getUser();
  console.log(user.is_managing_broker, 'user===>');

  const renderDashboard = () => {
    if (user.is_admin) {
      return <AdminDashboard />;
    } else if (user.is_operation) {
      return <OperationDashboard />;
    } else if (user.is_billing) {
      return <BillingDashboard />;
    } else if (user.is_managing_broker) {
      return <ManagingBrokerDashboard />;
    } else {
      return <div>Unauthorized Access</div>;
    }
  };

  return (
    <div className='h-screen overflow-y-scroll'>
      <DashboardHeader />
      {renderDashboard()}
    </div>
  );
};

export default AuthDashboard;
