
// import "@tremor/react/dist/esm/tremor.css";
import { Toaster } from 'sonner'
import { PageRoutes } from './routes';
import { HelmetProvider } from 'react-helmet-async';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient();
const helmetContext = {};

function App() {
  return (
    <>
      <Toaster richColors position="top-right"/>
      <HelmetProvider context={helmetContext}>
        <QueryClientProvider client={queryClient}>
          <PageRoutes />
        </QueryClientProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
