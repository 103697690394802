import { LineChart, BarChart } from '@tremor/react';



const dataFormatter = (number:number) =>
  //`$${Intl.NumberFormat('us').format(number).toString()}`;
  `${Intl.NumberFormat('us').format(number).toString()}`;

export  default function LineChartData({ chartdata }:any) {
  return (
    <BarChart
      className="h-64 text-xxsm"
      data={chartdata}
      index="date"
      categories={['created showings', 'accepted showings', 'completed showings', 'disputed showings']}
      colors={['indigo', 'green', 'yellow', 'red']}
      valueFormatter={dataFormatter}
      yAxisWidth={60}
      // minValue={1} 
      // maxValue={15}
      onValueChange={(v) => console.log(v)}
    />
  );
}