import React, { useState, useEffect, Suspense } from 'react';
import { Formik, Form } from "formik";
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { Search, X } from 'lucide-react';
import { AiOutlineClose } from 'react-icons/ai'
import { AgentApplicationListCard } from '../../../components/cards';
import { useListAgents } from '../../../hooks/get/useListAgents';
import { useCreateUser } from '../../../hooks/post/useCreateUser';
import { PlaceholderCard } from '../../../components/skeleton';
import { ModalDialog } from '../../../components/modal';
import { TextInput } from '../../../components/forms/inputs';
import { Button, CreateButton } from '../../../components/button';
import { useModalContext } from '../../../contexts/modalContext';
import { CreateOperationOrBillingFormSchema } from '../../../utils/yupSchemas';
import { countriesFlagPhoneExts } from '../../../utils/countriesFlagPhoneExts';
import { PhoneInput } from '../../../components/forms/inputs';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/SelectFilter"
import { ItemSearch } from '../../../components/search';
import SimpleSpinner from '../../../components/loaders/SimpleSpinner';
import { NoData } from '../../../components/placeholders';
import CustomModal from '../../../components/modal/CustomModal';
import logo from '../../../assets/png/showingxchange-logo.png'
import { pageURL } from '../../../components/navigation/pagesURL';



const ListOperations = () => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchCountryQuery, setCountrySearchQuery] = useState<string>('');
    const [operations, setOperations] = useState([]);
    const [loadingOperations, setLoadingOperations] = useState(false);
    const [showCountrySearch, setShowCountrySearch] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState({name: 'United States', flag: '🇺🇸', code: 'US', dial_code: '+1'});
    const [selectedUserType, setSelectedUserType] = useState<string>('')
    const listOperations = useListAgents();
    const createUser = useCreateUser();

    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);
  
    const userTypes = ['operation', 'billing'];
  
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      setSelectedUserType(value)
    };

    const navigateTo = useNavigate();
    const handleRedirect = (slug:string)=>{
      navigateTo(pageURL.agentProfile + '/' + slug, { state: 'operation' });
    }
  
    const handleSelectCountry = (country:any)=>{
      setSelectedCountry(country)
    }
  
    const handleToggleCountrySearch = ()=>{
      setShowCountrySearch(!showCountrySearch) 
    }
  
  
    const handleCountrySearchQuery = (e:any)=>{
      setCountrySearchQuery(e.target.value);
      }
  
      const handleSearchQuery = (e:any)=>{
          setSearchQuery(e.target.value);
      }
  
      const filterdAgents = operations && operations.filter((agent:any)=>{
          const result = `${agent?.first_name} ${agent?.gender} ${agent?.last_name} ${agent?.agent_country}`;
          return result.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) 
      })

  
      const filterdCountries = countriesFlagPhoneExts && countriesFlagPhoneExts.filter((country)=>{
        const result = `${country?.name} ${country?.dial_code}`;
        return result.toLocaleLowerCase().includes(searchCountryQuery.toLocaleLowerCase()) 
    })
  
      useEffect(()=>{
        async function listOperationUsers(){
          try {
            setLoadingOperations(true);
            const { status, data  } = await listOperations('list-operation-applications');
            console.log(data, 'data====>')
            if(status===200){
              setOperations(data?.data)
            }
          } catch (error) {
            console.log(error);
          }finally{
            setLoadingOperations(false)
          }
        }
        listOperationUsers();
      },[]);

  return (
    <div className='font-primary'>
      {
        loadingOperations ? (
          <div className="w-11/12 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1 m-auto mt-10">
              {Array.from({ length: 8}).map((_, i) => (
              <PlaceholderCard key={i} />
              ))}
          </div>
        ):(
          <>
            {
                operations && operations.length > 0 ? (
                    <div className='bg-white rounded-md shadow-lg mx-3 p-5 mt-5'>
                        <div className='flex items-center justify-between border-b pb-3'>
                        <h3 className='font-bold text-xsm'>Operations Applications ({operations && operations.length})</h3>
                        <div>
                            <Select>
                            <SelectTrigger className="md:w-[180px]">
                                <SelectValue placeholder="All" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="light">Approved</SelectItem>
                                <SelectItem value="system">Pending</SelectItem>
                                <SelectItem value="dark">Rejected</SelectItem>
                            </SelectContent>
                            </Select>
                        </div>
                        </div>
                        <div className='flex justify-end mt-10'>
                          <div className='md:w-3/12'>
                            <CreateButton open={handleOpenModal} btnText={'Create Operation'}/>
                          </div>
                        </div>
                        <ItemSearch
                        searchQuery={searchQuery}
                        handleSearchQuery={handleSearchQuery}
                        />
                        <div className='h-auto my-5 overflow-y-auto'>
                        {   
                            <div className='overflow-y-auto'>
                                <table className="table table-sm">
                                <thead>
                                    <tr>
                                    <th>Name</th>
                                    <th>Job</th>
                                    <th>Full Name</th>
                                    <th>Status</th>
                                    <th>Details</th>
                                    {/* <th>Action</th> */}
                                    </tr>
                                </thead>
                                <>
                                  {
                                    filterdAgents.length > 0 
                                    ? (
                                    <>
                                      {
                                        filterdAgents && filterdAgents.map((application: any, i: any)=>(
                                        // <AgentApplicationListCard 
                                        //   application={application} 
                                        //   i={i} key={i}
                                        //   handleToggleAssignAgentModal={handleOpenModal}
                                        //   setActiveSlug={()=>{}}
                                        //   userType='operation'
                                        // />
                                        <tbody>
                                        {/* row 1 */}
                                          <tr>
                                            <th>
                                              <label>
                                                <input type="checkbox" 
                                                className="checkbox checked:border-[#3EB489 [--chkbg:#808080] [--chkfg:#fff]" />
                                              </label>
                                            </th>
                                            <td>
                                              <div className="flex items-center gap-3">
                                                <div className="avatar">
                                                  <div className=" w-12 h-12 border border-slate-400 p-1 rounded-full">
                                                    <img src={application.upload_picture} alt="showing exchange profile" className='h-full w-full rounded-full' />
                                                  </div>
                                                </div>
                                                <div>
                                                  <div className="font-bold">{application?.last_name?.substring(0,10)}</div>
                                                  <div className="text-sm opacity-50">{application?.agent_country}</div>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              {application?.last_name}
                                              <br/>
                                              <span className="badge badge-ghost badge-sm">{application?.first_name}</span>
                                            </td>
                                            <td><span className={`badge  badge-sm 
                                            ${application?.user?.is_application_approved === 'Approved' 
                                            ? 'bg-customPrimary'
                                            : application?.user?.is_application_approved === 'Submitted' 
                                            ? 'bg-yellow-600' : 'badge-ghost'} `}>{application?.user?.is_application_approved}</span></td>
                                            <th>
                                              <button className="btn btn-ghost btn-xs" onClick={()=>handleRedirect(application?.slug)}>View Details</button>
                                            </th>
                                            {/* <th>
                                              <button className="btn btn-ghost btn-xs" onClick={()=>{
                                                handleToggleAssignAgentModal();
                                                setActiveSlug(application?.slug)
                                              }}>Assign to me</button>
                                            </th> */}
                                          </tr>
                                        </tbody>
                                        ))
                                      }
                                    </>)
                                    : 
                                    (<div  className='h-full flex justify-center items-center mt-10'>
                                      <h3>No result found for <strong>{searchQuery}</strong> </h3>
                                    </div>)
                                  }
                                </>
                                </table>
                            </div>
                            
                        }
                        </div>
                    </div>
                ):(
                  <div className='h-screen flex flex-col justify-center items-center gap-10'>
                    <NoData
                        icon={<Search size={50}/>}
                    >
                        <h3 className='text-normal'>No operation user <br /> found</h3>
                    </NoData>
                    <div className='w-3/12'>
                        <CreateButton open={handleOpenModal} btnText={'Create Operation'}/>
                    </div>
                  </div>
                )
            }
          </>
        )
      }
       {isModalOpen && (
        <CustomModal
          width="w-[300px] md:w-5/12"
          icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
          iconRemove={<AiOutlineClose onClick={handleCloseModal} className="w-6 h-6 text-[#667085]" />}
          title="Create operations Account"
          description=""
          bgColor="bg-white"
          textColor="text-black"
          closeModal={handleCloseModal}
          isModalOpen={isModalOpen}
        >
          <Formik
            initialValues={{ email: "", phone_number:"", password: "", }}
            validationSchema={CreateOperationOrBillingFormSchema}
         
            onSubmit={async (values, { setSubmitting }) => {
              const operationsUserPayload = {
                email: values.email,
                password: values.password,
                phone_number: values.phone_number,
                country_code: selectedCountry.dial_code,
                is_operation: true
              }
              const billingsUserPayload = {
                email: values.email,
                password: values.password,
                phone_number: values.phone_number,
                country_code: selectedCountry.dial_code,
                is_billing: true
              }
              try {
                //const { status, data, error } = await createUser(selectedUserType === 'operation' ? operationsUserPayload : billingsUserPayload);
                const { status, data, error } = await createUser(operationsUserPayload);
                console.log(status)
                console.log(data)
                if (status === 201) {
                  toast.success(data?.message);
                  handleCloseModal();
                }
                if(status !==201){
                  toast.error(error)
                }
              } catch (error:any) {
                return error
              }
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
              <div>
                <TextInput
                  label='user email'
                  name='email'
                  icon={undefined}
                  type='email'
                  placeholder='email'
                />
                <>
                <h3 className='text-sm font-normal mb-1 ml-1'>Enter User Phone Number</h3>
                  <div className='flex items-center relative'>
                    <div className='md:w-3/12'>
                        <div className='p-3 flex items-center gap-3 border cursor-pointer text-sm' onClick={handleToggleCountrySearch}>
                            <h3>{selectedCountry.flag}</h3>
                            <span>{selectedCountry.dial_code}</span>
                        </div>
                        <div className=''>
                            {
                                showCountrySearch && (
                                    <div className='w-full h-64 p-5 shadow-lg mt-10  absolute top-10 left-0 bg-white z-10 overflow-y-scroll'>
                                        <input 
                                            type="search"
                                            className='w-full rounded-md px-5 py-3 mt-2 outline-none border'
                                            placeholder='search country code'
                                            value={searchCountryQuery}
                                            onChange={handleCountrySearchQuery}
                                        />
                                        <ul className=''>
                                            {
                                                filterdCountries.map((country, i)=>(
                                                    <li className='flex items-center gap-3 border-b py-2 cursor-pointer' key={i} onClick={()=>{
                                                        handleSelectCountry(country)
                                                        handleToggleCountrySearch()
                                                    }}>
                                                        <span>{country.flag}</span><h3>{country.name}({country.dial_code})</h3>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                     <PhoneInput
                      name='phone_number'
                      icon={undefined}
                      type='number'
                      placeholder='user phone number'
                    />
                  </div>
                </>
                <div className='relative'>
                  <TextInput
                    label='user password'
                    name='password'
                    icon={undefined}
                    type='password'
                    placeholder='password'
                  />
                </div>
              </div>
              {
                isSubmitting ? 
                <div className='flex justify-center items-center py-5'><SimpleSpinner size={'lg'}/></div> :
                (
                  <Button 
                  disabled={isSubmitting}
                  isBorder={false}
                  >
                    {isSubmitting ? "creating your account" : "Create Operation Account" }
                  </Button>
                )
              }
            </Form>
            )}
          </Formik>
        </CustomModal>
      )}
    </div>
  )
}

export default ListOperations