import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePostRequestSlug } from '../../../hooks/post/usePostRequestSlug';
import { CustomTextInput } from '../inputs';
import { SettingsBtn } from '../../button';
import { createBrokerProfilePath } from '../../../config/api-urls';
import { SimpleSpinner } from '../../loaders';
import { toast } from 'sonner';
import { pageURL } from '../../navigation/pagesURL';
import { Pencil, Plus, Trash2 } from 'lucide-react';




interface ICreateBrokerProfileFormProps {
    handleCloseCreateProfileModal: ()=> void;
    selectedProfile: any
}

interface IBrokerageId {
    brokerage_id: string
  }
  

const CreateBrokerProfileForm = ({ handleCloseCreateProfileModal, selectedProfile }: ICreateBrokerProfileFormProps) => {

    const [isChecked, setIsChecked] = useState(false);
    // const [isTimerChecked, setIsTimerChecked] = useState(false);
    const [brokerages, setBrokerages] = useState<IBrokerageId[]>([])
    const [newBrokerageId, setNewBrokerageId] = useState("");
    const [brokerageBroadcastTimeout, setBrokerageBroadcastTimeout] = useState("");
    const [editingId, setEditingId] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const postRequestSlug = usePostRequestSlug();
    const navigateTo = useNavigate();

    const [errors, setErrors] = useState({
        brokerage_term_start_date: '',
        brokerage_term_expire_date: ''
      });

    const [formData, setFormData] = useState({
            first_name: " ",
            last_name: "Doe",
            user_type: "Managing Broker",
            middle_name: "mark",
            brokerage_name: "test broker inc",
            brokerage_unit_charge: '',
            brokerage_term_start_date: "2024-10-21",
            brokerage_term_expire_date: "2024-11-21",
            // brokerage_broadcast_out: false,
            // brokerage_broadcast_out_timeout: "",
            is_related_brokerage: false,
            related_brokerages: [],
            brokerage_address1: "shen",
            brokerage_address2: "lo kazong",
            brokerage_city: "jos",
            brokerage_state: "plateau",
            zip_code: "920112",
            brokerage_country: "Nigeria"
    });

    const placeholders = {
        first_name: "Enter first name",
        last_name: "Enter last name",
        user_type: "Managing Broker",
        middle_name: "enter middle name",
        brokerage_name: "brokerage name",
        brokerage_id: "BKID746197489",
        brokerage_unit_charge: 'brokerage unit charge',
        brokerage_term_start_date: "brokerage start date (YY-MM-DD)",
        brokerage_term_expire_date: "brokerage end date (YY-MM-DD)",
        // brokerage_broadcast_out: true,
        // brokerage_broadcast_out_timeout: "3600",
        is_related_brokerage: true,
        related_brokerages: [],
        brokerage_address1: "brokerage address line 1",
        brokerage_address2: "brokerage address line 2",
        brokerage_city: "brokerage city",
        brokerage_state: "brokerage state",
        zip_code: "zip code",
        brokerage_country: "brokerage country"
    }

    const requiredFields = [
      'validated_real_licence_expiry_date',
      'validated_real_licence_status',
      'validated_real_licence_address',
      'validated_real_licence_type',
      'comments_private',
      'comments_public',
      'validated_dl_first_name',
      'validated_dl_middle_name',
      'validated_dl_last_name',
      'validated_dl_number',
    ]
    


    const dateRegex = /^\d{2}-\d{2}-\d{2}$/; // Matches YY-MM-DD format

    const validateDateFields = () => {
        let tempErrors = { brokerage_term_start_date: '', brokerage_term_expire_date: '' };
        let isValid = true;
    
        // Validate brokerage_term_start_date
        if (!dateRegex.test(formData.brokerage_term_start_date)) {
          tempErrors.brokerage_term_start_date = 'Start date must be in YY-MM-DD format';
          isValid = false;
        }
    
        // Validate brokerage_term_expire_date
        if (!dateRegex.test(formData.brokerage_term_expire_date)) {
          tempErrors.brokerage_term_expire_date = 'Expire date must be in YY-MM-DD format';
          isValid = false;
        }
    
        setErrors(tempErrors);
        return isValid;
      };
    

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
        const { name, value } = e.target;
        setFormData({
            ...formData, [name]:value
        })
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        setFormData(prev=>({
            ...prev, is_related_brokerage: isChecked
        }))
      };
    
      const handleAddBrokerage = useCallback(() => {
        if (newBrokerageId.trim() !== "") {
          setBrokerages((prev) => [...prev, { brokerage_id: newBrokerageId }])
          setNewBrokerageId("")
        }
        toast.success('Id Added successfully');
      }, [newBrokerageId])
    
      const handleEditBrokerage = useCallback((id: string) => {
        setEditingId(id)
        const brokerage = brokerages.find((b) => b.brokerage_id === id)
        if (brokerage) {
          setNewBrokerageId(brokerage.brokerage_id)
        }
      }, [brokerages])
    
      const handleUpdateBrokerage = useCallback(() => {
        if (editingId && newBrokerageId.trim() !== "") {
          setBrokerages((prev) =>
            prev.map((b) =>
              b.brokerage_id === editingId ? { brokerage_id: newBrokerageId } : b
            )
          )
          setNewBrokerageId("")
          setEditingId(null)
        }
      }, [editingId, newBrokerageId])
    
      const handleDeleteBrokerage = useCallback((id: string) => {
        setBrokerages((prev) => prev.filter((b) => b.brokerage_id !== id));
        toast.success('Deleted successfully');
      }, [])

    const handleCreateBrokerProfile = async()=>{
        if(validateDateFields()){
            toast.error('date format must be YY-MM-DD');
            return
        }
        const payload = {
            first_name: formData.first_name,
            last_name: formData.last_name,
            user_type: "Managing Broker",
            middle_name: formData.middle_name,
            brokerage_name: formData.brokerage_name,
            //brokerage_id: "BKID746197489",
            brokerage_unit_charge: Number(formData.brokerage_unit_charge),
            brokerage_term_start_date: "2024-10-21",
            brokerage_term_expire_date: "2024-11-21",
            // brokerage_broadcast_out: isTimerChecked,
            // brokerage_broadcast_out_timeout: 60 * 60 * Number(brokerageBroadcastTimeout),
            is_related_brokerage: isChecked,
            related_brokerages: brokerages,
            brokerage_address1: formData.brokerage_address1,
            brokerage_address2: formData.brokerage_address2,
            brokerage_city: formData.brokerage_city,
            brokerage_state: formData.brokerage_state,
            zip_code: formData.zip_code,
            brokerage_country: formData.brokerage_country
        }
        try {
            setLoading(true);
            const { status, data } = await postRequestSlug(createBrokerProfilePath, selectedProfile.slug, payload);
            console.log(status, 'st');
            console.log(data, 'data');
            if(status===201){
                toast.success(data.message);
                handleCloseCreateProfileModal();
                navigateTo(pageURL.listBrokersCompletedProfile, { state: data?.data?.brokerage_id })
            }
        } catch (error: any) {
            if (error.response && error.response.data) {
                console.log(error, 'err')
                toast.error(`${error.response.data.message}`);
              } else if (error.request) {
                  toast.error('No response from server. Please try again later.');
              } else {
                  toast.error(`Error: ${error.data.message}`);
              }
        }finally{
            setLoading(false);
        }
    }

  return (
    <div className='font-primary'>
        <div className='grid md:grid-cols-3 gap-2'>
            <CustomTextInput
                label= {placeholders.first_name}
                handleChange={handleChange}
                inputAttrs={{
                    name: 'first_name',
                    placeholder: placeholders.first_name,
                    type: 'text',
                    value: formData.first_name,
                }}
            />
            <CustomTextInput
                label= {placeholders.last_name}
                handleChange={handleChange}
                inputAttrs={{
                    name: 'last_name',
                    placeholder: placeholders.last_name,
                    type: 'text',
                    value: formData.last_name,
                }}
            />
            <CustomTextInput
                label= {placeholders.middle_name}
                handleChange={handleChange}
                inputAttrs={{
                    name: 'middle_name',
                    placeholder: placeholders.middle_name,
                    type: 'text',
                    value: formData.middle_name,
                }}
            />
            <CustomTextInput
                label= {placeholders.brokerage_name}
                handleChange={handleChange}
                inputAttrs={{
                    name: 'brokerage_name',
                    placeholder: placeholders.brokerage_name,
                    type: 'text',
                    value: formData.brokerage_name,
                }}
            />
            <CustomTextInput
                label= {placeholders.brokerage_unit_charge}
                handleChange={handleChange}
                inputAttrs={{
                    name: 'brokerage_unit_charge',
                    placeholder: placeholders.brokerage_unit_charge,
                    type: 'number',
                    value: formData.brokerage_unit_charge,
                }}
            />
            <div>
                <CustomTextInput
                    label= {placeholders.brokerage_term_start_date}
                    handleChange={handleChange}
                    inputAttrs={{
                        name: 'brokerage_term_start_date',
                        placeholder: placeholders.brokerage_term_start_date,
                        type: 'text',
                        value: formData.brokerage_term_start_date,
                    }}
                />
                 {errors.brokerage_term_start_date && (
                    <p style={{ color: 'red' }}>{errors.brokerage_term_start_date}</p>
                )}
            </div>
            <div>
                <CustomTextInput
                    label= {placeholders.brokerage_term_expire_date}
                    handleChange={handleChange}
                    inputAttrs={{
                        name: 'brokerage_term_expire_date',
                        placeholder: placeholders.brokerage_term_expire_date,
                        type: 'text',
                        value: formData.brokerage_term_expire_date,
                    }}
                />
                 {errors.brokerage_term_start_date && (
                    <p style={{ color: 'red' }}>{errors.brokerage_term_expire_date}</p>
                )}
            </div>
                {/* <DateField /> */}
            <CustomTextInput
                label= {placeholders.brokerage_address1}
                handleChange={handleChange}
                inputAttrs={{
                    name: 'brokerage_address1',
                    placeholder: placeholders.brokerage_address1,
                    type: 'text',
                    value: formData.brokerage_address1,
                }}
            />
            <CustomTextInput
                label= {placeholders.brokerage_address2}
                handleChange={handleChange}
                inputAttrs={{
                    name: 'brokerage_address2',
                    placeholder: placeholders.brokerage_address2,
                    type: 'text',
                    value: formData.brokerage_address2,
                }}
            />
            <CustomTextInput
                label= {placeholders.brokerage_city}
                handleChange={handleChange}
                inputAttrs={{
                    name: 'brokerage_city',
                    placeholder: placeholders.brokerage_city,
                    type: 'text',
                    value: formData.brokerage_city,
                }}
            />
            <CustomTextInput
                label= {placeholders.brokerage_state}
                handleChange={handleChange}
                inputAttrs={{
                    name: 'brokerage_state',
                    placeholder: placeholders.brokerage_state,
                    type: 'text',
                    value: formData.brokerage_state,
                }}
            />
            <CustomTextInput
                label= {placeholders.zip_code}
                handleChange={handleChange}
                inputAttrs={{
                    name: 'brokerage_city',
                    placeholder: placeholders.zip_code,
                    type: 'text',
                    value: formData.zip_code,
                }}
            />
            <CustomTextInput
                label= {placeholders.brokerage_country}
                handleChange={handleChange}
                inputAttrs={{
                    name: 'brokerage_state',
                    placeholder: placeholders.brokerage_country,
                    type: 'text',
                    value: formData.brokerage_country,
                }}
            />
            <div>
                <div className="p-4 max-w-md mx-auto">
                    <div className="flex items-center space-x-2 mb-4">
                        <label htmlFor={'showForm'} className='capitalize'>Add Related Brokerage Id</label>
                        <input
                        id="showForm"
                        checked={isChecked}
                        type="checkbox"
                        name={'showForm'}
                        onChange={handleCheckboxChange}
                        className={`toggle border-slate-500 ${isChecked ? 'bg-customPrimary' : 'bg-slate-500'} hover:bg-slate-700`}
                        />
                    </div>
                    {isChecked && (
                        <div className="mb-4 flex items-center gap-1">
                        <input
                            type="text"
                            value={newBrokerageId}
                            onChange={(e) => setNewBrokerageId(e.target.value)}
                            placeholder="Enter Brokerage ID"
                            className="mb-2  text-[#989093] text-sm font-light py-1 px-2 outline-0 rounded-[8px] border border-[#E4E6F1] placeholder:text-xsm mt-2"
                        />
                        {editingId ? (
                            <button onClick={handleUpdateBrokerage} className='flex justify-center gap-1 border p-1 bg-[#000] text-white rounded-sm'>Save</button>
                        ) : (
                            <button onClick={handleAddBrokerage} className='flex justify-center gap-1 border p-1 bg-[#000] text-white rounded-sm'><Plus className='text-white'/> Add</button>
                        )}
                        </div>
                    )}

                    
                    </div>
                </div>
               <div className="space-y-2">
                        {brokerages.map((brokerage) => (
                        <div key={brokerage.brokerage_id} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                            <span>{brokerage.brokerage_id}</span>
                            <div className='h-full flex items-center gap-1'>
                            <button onClick={() => handleEditBrokerage(brokerage.brokerage_id)} className='h-full  bg-white flex justify-center gap-1  p-1 text-white rounded-sm'><Pencil className='text-[#000]'/></button>
                            <button onClick={() => handleDeleteBrokerage(brokerage.brokerage_id)} className='h-full bg-white  flex justify-center gap-1  p-1 text-white rounded-sm'><Trash2 className='text-red-600'/></button>
                            </div>
                        </div>
                        ))}
                    </div>
            </div>


        <div className='flex items-center justify-between mt-5'>
            <SettingsBtn
                isBorder={false}
                bgColor="bg-whiteSmoke"
                textColor="text-[#000]"
                width="md:w-4/12"
                onClick={handleCloseCreateProfileModal}
            >
            Cancel
            </SettingsBtn>
            <SettingsBtn
                isBorder={false}
                bgColor="bg-customPrimary"
                textColor="text-white"
                width="md:w-4/12"
                onClick={handleCreateBrokerProfile}
            >
            {
                loading ? <div className='flex justify-center items-center'><SimpleSpinner size={'lg'}/></div> : <span>Create profile</span>
            }
            </SettingsBtn>
        </div>
    </div>
  )
}

export default CreateBrokerProfileForm