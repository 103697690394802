import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { usePostRequest } from '../../../hooks/post/usePostRequest';
import { useGetRequestDetails } from '../../../hooks/get/useGetRequestDetails';
import { usePostRequestSlugNoPayload } from '../../../hooks/post/usePostRequestSlugNoPayload';
import { useDeleteRequest } from '../../../hooks/useDeleteRequest';
import { deleteBrokerageAgent, getBrokerAgentApplicationDetailsPath } from '../../../config/api-urls';
import { PlaceholderCard } from '../../../components/skeleton';
import { BrokerageAgentProfileCard } from '../../../components/cards';
import { 
  deactivateBrokerageAgent, 
  reactivateBrokerageAgent, 
  activateBrokerageAgent,
  brokerageAgentCreatedShowings, 
  brokerageAgentAcceptedShowings, 
  brokerageAgentCompletedShowings, 
  brokerageAgentConfirmShowings, 
  brokerageAgentDisputes, 
} from '../../../config/api-urls';
import { pageURL } from '../../../components/navigation/pagesURL';

const BrokerageAgentDetails = () => {
    const [isLoading, setIstLoading] = useState<boolean>(false);
    const { slug } = useParams();
    const getRequestDetails = useGetRequestDetails();
    const postRequestSlugNoPayload = usePostRequestSlugNoPayload();
    const postRequest = usePostRequest();
    const deleteRequest = useDeleteRequest();
    const queryClient = useQueryClient();
    const navigateTo = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);


    const { isPending, error, data } = useQuery({
      queryKey: ['broker-application-details'],
      queryFn: ()=> getRequestDetails(getBrokerAgentApplicationDetailsPath, slug),
       // staleTime: 10000,
      // enabled: false,
      retry: false
    });

    const agentDetails = data?.data?.data || {};
    const userSlug  = agentDetails?.user?.slug;

    

    const { isPending: agentCreatedRequestLoading, error: agentCreatedRequestError, data:  agentCreatedRequestData } = useQuery({
      queryKey: ['brokerage-agent-created-showings'],
      queryFn: ()=> getRequestDetails(brokerageAgentCreatedShowings, userSlug),
      retry: false
    });
    const { isPending: agentAcceptedRequestLoading, error: agentAcceptedRequestError, data:  agentAcceptedRequestData } = useQuery({
      queryKey: ['brokerage-agent-accepted-showings'],
      queryFn: ()=> getRequestDetails(brokerageAgentAcceptedShowings, userSlug),
      retry: false
    });
    const { isPending: agentCompletedRequestLoading, error: agentCompletedRequestError, data:  agentCompletedRequestData } = useQuery({
      queryKey: ['brokerage-agent-completed-showings'],
      queryFn: ()=> getRequestDetails(brokerageAgentCompletedShowings, userSlug),
      retry: false
    });
    const { isPending: agentConfirmedRequestLoading, error: agentConfirmedRequestError, data:  agentConfirmedRequestData } = useQuery({
      queryKey: ['brokerage-agent-confirm-showings'],
      queryFn: ()=> getRequestDetails(brokerageAgentConfirmShowings, userSlug),
      retry: false
    });
    const { isPending: agentDisputedRequestLoading, error: agentDisputedRequestError, data:  agentDisputedRequestData } = useQuery({
      queryKey: ['brokerage-agent-disputes'],
      queryFn: ()=> getRequestDetails(brokerageAgentDisputes, userSlug),
      retry: false
    });

    const listAgentCreatedRequests  = agentCreatedRequestData?.data?.data || []
    const listAgentAcceptedRequests  = agentAcceptedRequestData?.data?.data || []
    const listAgentCompletedRequests  = agentCompletedRequestData?.data?.data || []
    const listAgentConfirmedRequests  = agentConfirmedRequestData?.data?.data || []
    const listAgentDisputedRequests  = agentDisputedRequestData?.data?.data || []
   

    // const deactivateMutation = useMutation({
    //   mutationFn: () => postRequestSlugNoPayload(deactivateBrokerageAgent, slug),
    //   onSuccess: (data) => {
    //     queryClient.invalidateQueries({ queryKey: ['broker-application-details'] }); 
    //     toast.success(data?.data?.message);
    //   },
    //   onError: (error) => {
    //     toast.error('Failed to deactivate agent. Please try again.');
    //     console.error("Failed to deactivate agent:", error);
    //   }
    // });
  
    // const reactivateMutation = useMutation({
    //   mutationFn: () => postRequestSlugNoPayload(reactivateBrokerageAgent, slug),
    //   onSuccess: (data) => {
    //     queryClient.invalidateQueries({ queryKey: ['broker-application-details'] });
    //     toast.success(data?.data?.message);
    //   },
    //   onError: (error) => {
    //     toast.error('Failed to reactivate agent. Please try again.');
    //     console.error("Failed to reactivate agent:", error);
    //   }
    // });

    const handleReActivateAgent = async()=>{
      const payload = {
          activation_type: "single",
          agent_application_slug: slug,
          agent_application_slug_list: [slug]
      }
      setIstLoading(true);
      try {
        const { status, data } = await postRequest(activateBrokerageAgent, payload);
        if(status===201){
          toast.success(data.message);
          window.location.href = data.data.link;
        }
      } catch (error: any) {
        if (error.response && error.response.data && error.response.data) {
          toast.error(`${error.response.data.message}`);
        } else if (error.request) {
            toast.error('No response from server. Please try again later.');
        } else {
            toast.error(`${error.message}`);
        }
      }finally{
        setIstLoading(false);
      }
    }

    const handleDeactivateAgent = async()=>{
      const payload = {
          agent_application_slug_list: [slug],
      }
      setIstLoading(true);
      try {
        const { status, data } = await postRequest(deactivateBrokerageAgent, payload);
        if(status===201){
          toast.success(data.message);
          queryClient.invalidateQueries({ queryKey: ['broker-application-details'] }); 
        }
      } catch (error: any) {
        if (error.response && error.response.data && error.response.data) {
          toast.error(`${error.response.data.message}`);
        } else if (error.request) {
            toast.error('No response from server. Please try again later.');
        } else {
            toast.error(`${error.message}`);
        }
      }finally{
        setIstLoading(false);
      }
    }
    const handleDeleteAgent = async()=>{
      setIstLoading(true);
      try {
        const { status, data } = await deleteRequest(deleteBrokerageAgent, slug);
        console.log(status);
        console.log(data);
        if(status===200){
          toast.success(data.message);
          navigateTo(pageURL.listBrokerAgents)
        }
      } catch (error: any) {
        if (error.response && error.response.data && error.response.data) {
          toast.error(`${error.response.data.message}`);
        } else if (error.request) {
            toast.error('No response from server. Please try again later.');
        } else {
            toast.error(`${error.message}`);
        }
      }finally{
        setIstLoading(false);
      }
    }
  

    if (isPending) {
      return (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
          {Array.from({ length: 8 }).map((_, i) => (
            <PlaceholderCard key={i} />
          ))}
        </div>
      );
    }
  
    if (error) {
      return <div>Error fetching agent details: {error.message}</div>;
    }
  

  return (
    <>
      {agentDetails ? (
        <div>
          {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          <BrokerageAgentProfileCard
            isLoading={isLoading}
            agentDetails={agentDetails}
            handleOpenModal={handleOpenModal}
            handleReActivateAgent={handleReActivateAgent}
            handleDeactivateAgent={handleDeactivateAgent}
            handleDeleteAgent={handleDeleteAgent}
            listAgentCreatedRequests={listAgentCreatedRequests}
            listAgentAcceptedRequests={listAgentAcceptedRequests} 
            listAgentCompletedRequests={listAgentCompletedRequests} 
            listAgentConfirmedRequests={listAgentConfirmedRequests} 
            listAgentDisputedRequests={listAgentDisputedRequests} 
            userSlug={userSlug}
          />
        </div>
      ) : (
        <div>
          No Profile Found
        </div>
      )}
    </>
  )
}

export default BrokerageAgentDetails