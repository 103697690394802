import React, { useState, useEffect } from 'react'
import { Eye, UserCheck, CarTaxiFront, CarIcon, Scale, ShieldCheck } from 'lucide-react';
import { toast } from 'sonner';
import { ModalDialog, FormDialog } from '../modal';
import { useAssignAgent } from '../../hooks/post/useAssignAgent';
import { COLORS } from '../../utils/constants/colors';
import coreClient from '../../services/coreApi';
import { SimpleSpinner } from '../loaders';
import { VerifyAgentDocuments } from '../forms/user-forms';
import { baseURL } from '../../services/baseURL.service';

const AgentProfileCard = ({ agentDetails }:{ agentDetails:any }) => {

    const [profileImagePreview, setProfileImagePreview] = useState(false);
    const [frontDriversLicensePreview, setFrontDriversLicensePreview] = useState(false);
    const [backDriversLicensePreview, setBackDriversLicensePreview] = useState(false);
    const [verifyDocumentsModal, setverifyDocumentsModal] = useState(false);
    const [loading, setLoading] = useState<Boolean>(false);
    const [showApproveModal, setShowApproveModal] = useState<Boolean>(false);
    const [selectedAction, setSelectedAction] = useState<string>('');
    const [loadingAssignAgent, setLoadingAssignAgent] = useState(false);
    const [showAssignAgentModal, setShowAssignAgentModal] = useState(false);
    const assignAgent = useAssignAgent();
    const [formData, setFormData] = useState({
        comment:''
    });

    const handleChange = (e:any)=>{
        const { name, value } = e.target
        setFormData({...formData, [name]:value })
    }

    const handleVerifyDocumentsModal = ()=>{
        setverifyDocumentsModal(!verifyDocumentsModal);
    }

    const handleToggleProfileImagePreview = ()=>{
        setProfileImagePreview(!profileImagePreview);
    }

    const handleSelectedAction = (action:string)=>{
        setSelectedAction(action);
        handleToggleApproveModal();
    }
    console.log(selectedAction, 'selectedAction')

    const handleToggleApproveModal = ()=>{
        setShowApproveModal(!showApproveModal);
      }

    const handleToggleFrontDriversLicensePreview = ()=>{
        setFrontDriversLicensePreview(!frontDriversLicensePreview);
    }
    const handleToggleBacktDriversLicensePreview = ()=>{
        setBackDriversLicensePreview(!backDriversLicensePreview);
    }

    

    const actions = ['Approve', 'Deny', 'Incomplete'];

    const handleToggleAssignAgentModal = ()=>{
        setShowAssignAgentModal(!showAssignAgentModal);
      }

    const handleAssignAgent = async()=>{
        try {
          setLoadingAssignAgent(true);
          //const { status, data } = await coreClient.post(`${process.env.REACT_APP_API_URL}/assign-agent-application/${activeSlug}/`);
          const { status, data } = await assignAgent({ slug:agentDetails?.slug }, { endpoint:'assign-agent-application' });
          if (status === 200) {
            toast.success(`${data.message}`);
            handleToggleAssignAgentModal();
            window.location.reload();
          } 
        } catch (error:any) {
          if (error.response && error.response.data && error.response.data) {
            toast.error(`${error.response.data.message}`);
          } else if (error.request) {
              toast.error('No response from server. Please try again later.');
          } else {
              toast.error(`${error.message}`);
          }
        }finally{
          setLoadingAssignAgent(false)
        }
      }

    const handleApprove = async()=>{
        //Approved, Denied or Insufficient
        if(!formData.comment){
            toast.error('You must provide a comment to continue');
            return
        }
        const selectedStatus = selectedAction==='Approve' ? 'Approved' 
        : selectedAction==='Deny' ? 'Denied' 
        : selectedAction==='Incomplete' ? 'Insufficient' 
        : null;
        try {
          setLoading(true);
          const { status, data } = await coreClient.post(`${baseURL}/review-agent-application/${agentDetails?.slug}/`,
            {
              current_status: selectedStatus,
              comment: formData.comment,
            }
          );
          if (status === 200) {
            toast.success(`${data.message}`);
            setFormData({ comment:'' })
            handleToggleApproveModal();
          } 
        } catch (error:any) {
          if (error.response && error.response.data && error.response.data) {
            toast.error(`${error.response.data.message}`);
          } else if (error.request) {
              toast.error('No response from server. Please try again later.');
          } else {
              toast.error(`${error.message}`);
          }
        }finally{
          setLoading(false)
        }
      }


  return (
    <div className='h-full w-full font-primary'>
        <div className='w-full md:h-32 px-5 md:px-10 flex items-center justify-between bg-customPrimar text-whit border-b'>
            <div className='flex items-center gap-4 '>
                <div className=" w-24 h-24 border-2 border-customPrimary p-1 rounded-full">
                    <img src={agentDetails?.upload_picture} alt="agent profile" className='h-full w-full rounded-full'/>
                </div>
                <div className=''>
                    <h3 className='font-normal capitalize'>{agentDetails?.first_name} {agentDetails?.last_name}</h3>
                    <h3>{agentDetails?.agent_country}</h3>
                    <h3 className='text-xsm'>status: <span className='rounded-full p-1 border px-5 text-xxsm'>{agentDetails?.user?.is_application_approved}</span></h3>
                </div>
            </div>
          {
            agentDetails?.is_assigned === false 
            ? (<>
            <button 
                    onClick={handleToggleAssignAgentModal}
                    className='w-2/12 py-3 px-5 flex justify-center items-center gap-3 bg-customPrimary text-white font-bold rounded-md hover:bg-gray-700 duration-500`'>
                    Assign Agent
                    <ShieldCheck />
            </button>
            </>) : (
                <div className='w-5/12 flex gap-3'>
                        <button 
                                onClick={handleVerifyDocumentsModal}
                                className='w-full py-3 px-5 flex justify-center items-center gap-3 bg-[#000] text-white font-bold rounded-md hover:bg-gray-700 duration-500`'>
                                Verify Documents
                                <ShieldCheck />
                        </button>
                        <select 
                            className="select select-bordered w-full select-md max-w-xs" 
                            onChange={(e) => handleSelectedAction(e.target.value)}
                            >
                            <option disabled selected>Select Action</option>
                            {
                                actions.map((action, index) => (
                                <option 
                                    value={action} 
                                    key={index}
                                >
                                    {action}
                                </option>
                                ))
                            }
                        </select>
                </div>
            )
          }
        </div>
        <div className='h-full flex'>
            <div className='w-4/12 border-r p-5'>
                <h3 className='my-5 font-normal flex items-center gap-3'> <span><UserCheck color={COLORS.primary} /></span>Personal information</h3>
                <ul className='flex flex-col gap-3 list-disc px-5'>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>first name</span>
                        <h3 className='font-normal'>{agentDetails?.first_name}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>middle name</span>
                        <h3 className='font-normal'>{agentDetails?.middle_name}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>last name</span>
                        <h3 className='font-normal'>{agentDetails?.last_name}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>Email</span>
                        <h3 className='font-normal'>{agentDetails?.user?.email}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>Phone Number</span>
                        <h3 className='font-normal'>{agentDetails?.user?.phone_number}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>date of birth</span>
                        <h3 className='font-normal'>{agentDetails?.date_of_birth}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>Address</span>
                        <h3 className='font-normal'>{agentDetails?.agent_address1}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>City</span>
                        <h3 className='font-normal'>{agentDetails?.agent_state}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>state</span>
                        <h3 className='font-normal'>{agentDetails?.agent_state}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>zip code</span>
                        <h3 className='font-normal'>{agentDetails?.zip_code}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>country</span>
                        <h3 className='font-normal'>{agentDetails?.agent_country}</h3>
                    </li>
                    <li className='flex items-center justify-between'>
                        <span className='text-slate-500 capitalize'>Profile picture</span>
                        {/* <h3 className='font-normal'>USA</h3> */}
                        <button
                        onClick={handleToggleProfileImagePreview} 
                        className='flex items-center gap-3 text-white bg-customPrimary px-3 py-1 rounded-full capitalize text-xsm'>
                            preview <span><Eye size={15} color='white' /></span>
                        </button>
                    </li>
                </ul>
            </div>
            <div className='w-8/12 min-h-full overflow-y-auto'>
            <div className='border-b p-5'>
                    <h3 className='my-5 font-normal flex items-center gap-3'> <span><CarIcon color={COLORS.primary} /></span>Drivers Licence Information</h3>
                    <ul className='grid grid-cols-3 gap-2  px-5'>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>Drivers Licence Number</span>
                            <h3 className='font-normal'>{agentDetails?.drivers_licence_number}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>Issuing State</span>
                            <h3 className='font-normal'>{agentDetails?.issuing_state}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>Issuing Date</span>
                            <h3 className='font-normal'>{agentDetails?.issue_date}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>Expiry Date</span>
                            <h3 className='font-normal'>{agentDetails?.expiring_date}</h3>
                        </li>
                    
                        <li className='flex items-center justify-between'>
                            <span className='text-slate-500 capitalize'>Drivers Licence (Front)</span>
                            {/* <h3 className='font-normal'>USA</h3> */}
                            <button
                            onClick={handleToggleFrontDriversLicensePreview} 
                            className='flex items-center gap-3 text-white bg-customPrimary px-3 py-1 rounded-full capitalize text-xsm'>
                                preview <span><Eye size={15} color='white' /></span>
                            </button>
                        </li>
                        <li className='flex items-center justify-between'>
                            <span className='text-slate-500 capitalize'>Drivers Licence (Back)</span>
                            {/* <h3 className='font-normal'>USA</h3> */}
                            <button
                            onClick={handleToggleBacktDriversLicensePreview} 
                            className='flex items-center gap-3 text-white bg-customPrimary px-3 py-1 rounded-full capitalize text-xsm'>
                                preview <span><Eye size={15} color='white' /></span>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className='border-b p-5'>
                    <h3 className='my-5 font-normal flex items-center gap-3'> <span><Scale color={COLORS.primary} /></span>Real Estate License information</h3>
                    <ul className='grid grid-cols-3 gap-2  px-5'>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>managing broker first_name:</span>
                            <h3 className='font-normal'>{agentDetails?.managing_broker_first_name}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>rmanaging broker middle name:</span>
                            <h3 className='font-normal'>{agentDetails?.managing_broker_middle_name}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>managing broker last name:</span>
                            <h3 className='font-normal'>{agentDetails?.managing_broker_last_name}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage company name:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_company_name}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage address1:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_address1}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage address2:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_address2}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage city:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_city}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage state:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_state}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage zip_code:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_zip_code}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage country:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_country}</h3>
                        </li>
                    </ul>
                </div>
                <div className='border-b p-5'>
                    <h3 className='my-5 font-normal flex items-center gap-3'> <span><Scale color={COLORS.primary} /></span>Brokerage information</h3>
                    <ul className='grid grid-cols-3 gap-2  px-5'>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>real estate licence state:</span>
                            <h3 className='font-normal'>{agentDetails?.real_estate_licence_state}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>real estate licence number:</span>
                            <h3 className='font-normal'>{agentDetails?.real_estate_licence_number}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>real estate licence expiry date:</span>
                            <h3 className='font-normal'>{agentDetails?.real_estate_licence_expiry_date}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>real estate licence status:</span>
                            <h3 className='font-normal'>{agentDetails?.real_estate_licence_status}</h3>
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>

        <ModalDialog 
            modalOpen={profileImagePreview}
            modalClose={handleToggleProfileImagePreview}
        >
            <div className='mt-10'>
                <img src={agentDetails?.upload_picture} alt="agent profile" className='w-[35rem] h-[32rem] object-cover' />
            </div>
        </ModalDialog>
        <ModalDialog 
            modalOpen={frontDriversLicensePreview}
            modalClose={handleToggleFrontDriversLicensePreview}
        >
            <div className='mt-10'>
                <img src={agentDetails?.front_drivers_licence_picture} alt="agent profile" className='w-[35rem] h-[32rem] object-cover' />
            </div>
        </ModalDialog>
        <ModalDialog 
            modalOpen={backDriversLicensePreview}
            modalClose={handleToggleBacktDriversLicensePreview}
        >
            <div className='mt-10'>
                <img src={agentDetails?.back_drivers_licence_picture} alt="agent profile" className='w-[35rem] h-[32rem] object-cover' />
            </div>
        </ModalDialog>
        <FormDialog 
            modalOpen={verifyDocumentsModal}
            modalClose={handleVerifyDocumentsModal}
        >
            <VerifyAgentDocuments
                slug={agentDetails?.slug}
                handleVerifyDocumentsModal={handleVerifyDocumentsModal}
            />
        </FormDialog>
        {
        showApproveModal && (
          <div className="h-full w-full flex justify-center items-center bg-white/90 absolute top-0 left-0 ">
            <div className="w-5/12 bg-white p-5 shadow-lg rounded-md border border-slate-400">
              <h3 className="font-bold text-lg">{selectedAction==='Insufficient' ? 'Reject' : selectedAction} Agent</h3>
              <p className="py-4 font-normal text-sm">Are you sure you want to mark this as <b>{selectedAction==='Approve' ? 'Approved' : selectedAction==='Deny' ? 'Denied' : selectedAction}</b> for this agent?</p>
              <div>
                <label htmlFor="comment" className="font-normal text-sm">Your Comment</label>
                <textarea 
                    className="w-full p-3 rounded-sm mt-2 outline-0 border border-slate-400"
                    value={formData.comment}
                    rows={6}
                    name="comment" 
                    id="comment"
                    onChange={handleChange}
                />
              </div>
              <div className="w-full modal-action flex items-center gap-5">
                <button className="bg-whiteSmoke px-10 py-3 rounded-sm font-normal" onClick={handleToggleApproveModal}>Close</button>
                <button className="bg-customPrimary px-10 py-3 rounded-sm font-normal text-white" onClick={handleApprove}>
                  { loading ? <SimpleSpinner size={'sm'}/> : 'Yes' }
                </button>
              </div>
            </div>
          </div>
        )
      }
      {
        showAssignAgentModal && (
          <div className="h-full w-full flex justify-center items-center bg-white/60 absolute top-0 left-0 ">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Assign Agent</h3>
              <p className="py-4">Are you sure you want to assign this agent to yourself?</p>
              <div className="w-full modal-action flex items-center gap-5">
                <button className="bg-customPrimary px-10 py-3 rounded-sm font-normal text-white" onClick={handleAssignAgent}>
                  { loadingAssignAgent ? <SimpleSpinner size={'sm'}/> : 'Yes' }
                </button>
                <button className="bg-whiteSmoke px-10 py-3 rounded-sm font-normal" onClick={handleToggleAssignAgentModal}>Close</button>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default AgentProfileCard