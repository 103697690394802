import React, { useRef } from 'react';
import { toast } from 'sonner';
import { Copy } from 'lucide-react';

interface ICopyToClipboard {
  link: string;
}

const CopyToClipboard: React.FC<ICopyToClipboard> = ({ link }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      toast.success('Successfully copied!');
    } else {
      toast.error('Failed to copy link');
    }
  };

  return (
    <div className="flex justify-between gap-1 items-center mt-4">
      <input 
        ref={inputRef}
        value={link} 
        readOnly 
        className="w-full border rounded-md py-1 pl-3"
      />
      <button onClick={handleCopy}>
        <Copy size={25} />
      </button>
    </div>
  );
};

export default CopyToClipboard;
