
import coreClient from '../../services/coreApi';
import { baseURL } from "../../services/baseURL.service";


interface IAssignDisputeResponse {
  status: number;
  data?: any;
  error?: string;
}

export const useAssignDispute = () => {
  const assignDispute = async (slug:any) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const response = await coreClient.post(`${baseURL}/assign-dispute/${slug}/`);
      return { status: response.status, data: response.data } as IAssignDisputeResponse;
    } catch (error) {
      console.error('Error fetching agent details:', error);
      return { status: 500, error: 'An unknown error occurred' } as IAssignDisputeResponse;
    }
  };

  return assignDispute;
};
