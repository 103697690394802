
import React, { useEffect, useState} from 'react';
import { Formik, Form } from "formik";
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'sonner';
import { Search } from 'lucide-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { AiOutlineClose } from 'react-icons/ai'
import { useGetRequest } from '../../../hooks/get/useGetRequest';
import { useAuthPostRequest } from '../../../hooks/post/useAuthPostRequest';
import { getBrokersNoProfilePath, getBrokersCompletedProfilePath } from '../../../config/api-urls';
import { PlaceholderCard } from '../../../components/skeleton';
import { NoData } from '../../../components/placeholders';
import { SettingsBtn } from '../../../components/button';
import CustomModal from '../../../components/modal/CustomModal';
import { SimpleSpinner } from '../../../components/loaders';
import logo from '../../../assets/png/showingxchange-logo.png';
import { ItemSearch } from '../../../components/search';
import { CopyToClipboard } from '../../../components/copy-items';

const ListBrokersCompletedProfile = () => {
    const getRequest = useGetRequest();
    const authPostRequest = useAuthPostRequest();
    const queryClient = useQueryClient();
    const location = useLocation();
    // const profileId = location.state;


    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedProfileId, setSelectedProfileId] = useState<string>('');
    const [localProfileId, setLocalProfileId] = useState(location.state);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);



    const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleSelectedProfile = (profileId: string)=>{
        setSelectedProfileId(profileId);
        handleOpenModal();
    }

    const { isPending, error, data } = useQuery({
        queryKey: ['brokers-completed-profile'],
        queryFn: ()=> getRequest(getBrokersCompletedProfilePath),
         // staleTime: 10000,
        // enabled: false,
        retry: false
    });

    const listBrolkersNoprofile  = data?.data?.data || []

    const filteredQuery = Array.isArray(listBrolkersNoprofile)
    ? listBrolkersNoprofile.filter((broker: any) => {
        const result = `${broker?.email} ${broker?.phone_number}`;
        return result.toLowerCase().includes(searchQuery.toLowerCase());
    })
    : [];


    // useEffect(() => {
    //     if (localProfileId) {
    //         handleOpenModal();
    //         setLocalProfileId(null); 
    //     }
    // }, [localProfileId]);


  return (
    <div className='font-primary'>
        {
            isPending ? (
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
                    {Array.from({ length: 8 }).map((_, i) => (
                        <PlaceholderCard key={i} />
                    ))}
                </div>
            ):(
                <>
                    {listBrolkersNoprofile && listBrolkersNoprofile.length > 0 ? (
                    <div className='bg-white rounded-md shadow-lg mx-3 p-5 mt-5'>
                    {/* <div className='flex justify-end'>
                        <SettingsBtn
                            isBorder={false}
                            bgColor="bg-customPrimary"
                            textColor="text-white"
                            width="md:w-2/12"
                            onClick={handleOpenModal}
                        >
                        Create New Broker
                        </SettingsBtn>
                    </div> */}
                    <div className='flex items-center justify-between border-b pb-3'>
                    <h3 className=' text-xsm'>Number of Brokers with created Profile <strong>({listBrolkersNoprofile && listBrolkersNoprofile.length})</strong></h3>
                    </div>
                 
                    <ItemSearch
                    searchQuery={searchQuery}
                    handleSearchQuery={handleSearchQuery}
                    />
                    <div className='h-auto my-5 overflow-y-auto'>
                    {   
                        <div className='overflow-y-auto'>
                            <table className="table table-sm">
                            <thead>
                                <tr>
                                  <th></th>
                                <th>S/N</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Brokerage ID</th>
                                <th>Action</th>
                                </tr>
                            </thead>
                            <>
                              {
                                filteredQuery.length > 0 
                                ? (
                                <>
                                  {
                                    filteredQuery && filteredQuery?.map((broker: any, i: number)=>(
                                    <>
                                      <tbody key={i}>
                                      {/* row 1 */}
                                      <tr>
                                      <th>
                                          <label>
                                          <input type="checkbox" 
                                          className="checkbox checked:border-[#3EB489 [--chkbg:#808080] [--chkfg:#fff]" />
                                          </label>
                                      </th>
                                      <td>
                                          <div className=" w-9 h-9 border border-slate-400 rounded-full flex justify-center items-center">
                                                  <strong>{i +1 }</strong>
                                          </div>
                                      </td>
                                      <td>
                                          <span className="badge badge-ghost badge-sm">{broker?.first_name}</span>
                                      </td>
                                      <td>
                                          <span className="badge badge-ghost badge-sm">{broker?.last_name}</span>
                                      </td>
                                      <td>
                                          <span className="badge badge-ghost badge-sm">{broker?.user?.email}</span>
                                      </td>
                                      <td>
                                          <span className="badge badge-ghost badge-sm">{broker?.user?.phone_number}</span>
                                      </td>
                                      <td>
                                          <span className="badge badge-ghost badge-sm">{broker?.brokerage_id}</span>
                                      </td>
                                      <th>
                                          <button className="btn btn-ghost btn-xs" onClick={()=> handleSelectedProfile(broker?.brokerage_id)}>Copy Brokerage ID</button>
                                      </th>
                                      <th>
                                          <button className="btn btn-ghost btn-xs" onClick={()=>{}}>Details</button>
                                      </th>
                                      </tr>
                                  </tbody>
                                    </>
                                    ))
                                  }
                                </>)
                                : 
                                (<div  className='h-full flex justify-center items-center mt-10'>
                                  <h3>No result found for <strong>{searchQuery}</strong> </h3>
                                </div>)
                              }
                            </>
                            </table>
                        </div>
                        
                    }
                    </div>
                </div>
                    ) : (
                        <div className='h-screen flex flex-col gap-5 justify-center items-center'>
                            <NoData icon={<Search size={30} />}>
                                <h3 className='text-normal'>No Brokers Found</h3>
                            </NoData>
                            <SettingsBtn
                                isBorder={false}
                                bgColor="bg-customPrimary"
                                textColor="text-white"
                                width="md:w-2/12"
                                onClick={handleOpenModal}
                            >
                            Create Broker
                            </SettingsBtn>
                        </div>
                    )}
                </>
            )
        }

        <CustomModal
            width="w-[300px] md:w-5/12"
            icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
            iconRemove={<AiOutlineClose onClick={handleCloseModal} className="w-6 h-6 text-[#667085]" />}
            title="Create Broker"
            description="copy and share this broker's Id"
            bgColor="bg-white"
            textColor="text-black"
            closeModal={handleCloseModal}
            isModalOpen={isModalOpen}
        >
          <CopyToClipboard
            link={selectedProfileId}
          />
        </CustomModal>
    </div>
  )
}

export default ListBrokersCompletedProfile