import { PaymentSuccessCard } from '../../components/cards'

const SubmitApplicationSuccess = () => {
  return (
    <PaymentSuccessCard
      message='payment was successful'
    />
  )
}

export default SubmitApplicationSuccess