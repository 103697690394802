import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { X, CheckCircle2, AlertCircle, RotateCcw, Upload, Trash2, CheckCircle, FileUp } from "lucide-react";
import { toast } from 'sonner';
import { usePostRequest } from '../../../hooks/post/usePostRequest';
import { SimpleSpinner } from '../../loaders';
import { addBrokerageAgentsPath } from '../../../config/api-urls';
import coreClient from '../../../services/coreApi';

type UploadState = "uploading" | "completed" | "error"

interface UploadItem {
  file: File
  filename: string
  filesize: number
  uploadedSize: number
  state: UploadState
}

function UploadStateItem({ item, onRemove, onRetry }: { item: UploadItem, onRemove: () => void, onRetry: () => void }) {
  const progress = (item.uploadedSize / item.filesize) * 100

  return (
    <div className="bg-white rounded-lg p-4 mb-4 flex items-center">
      <div className="mr-4 flex-shrink-0">
        <div className="bg-whiteSmoke text-white text-xs font-bold px-2 py-1 rounded">CSV</div>
      </div>
      <div className="flex-grow">
        <div className="flex justify-between items-center mb-1">
          <span className="text-sm font-medium">{item.filename}</span>
          {item.state === "completed" && (
            <Trash2 className="w-5 h-5 text-gray-500 cursor-pointer" onClick={onRemove} />
          )}
          {item.state === "error" && (
            <RotateCcw className="w-5 h-5 text-gray-500 cursor-pointer" onClick={onRetry} />
          )}
        </div>
        <div className="text-xs text-gray-500 mb-2">
          {item.uploadedSize} KB of {item.filesize} KB
          {item.state === "uploading" && " • Uploading..."}
          {item.state === "completed" && <CheckCircle2 className="inline-block w-4 h-4 text-green-500 ml-1" />}
          {item.state === "error" && <AlertCircle className="inline-block w-4 h-4 text-red-500 ml-1" />}
        </div>
        <div className="bg-gray-200 rounded-full h-2">
          <div
            className={`rounded-full h-2 ${
              item.state === "error" ? "bg-red-500" : "bg-customPrimary"
            }`}
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default function CsvFileUpload({ handleCloseModal }: { handleCloseModal: ()=> void; }) {
  const [uploadItem, setUploadItem] = useState<UploadItem | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  const postRequest = usePostRequest();
  const navigateTo = useNavigate();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const newItem: UploadItem = {
        file,
        filename: file.name,
        filesize: Math.round(file.size / 1024),
        uploadedSize: 0,
        state: "uploading"
      }
      setUploadItem(newItem)
      simulateUpload()
    }
  }

  const simulateUpload = () => {
    const interval = setInterval(() => {
      setUploadItem(prev => {
        if (prev && prev.state === "uploading") {
          const newUploadedSize = Math.min(prev.uploadedSize + 10, prev.filesize)
          if (newUploadedSize === prev.filesize) {
            clearInterval(interval)
            return { ...prev, uploadedSize: newUploadedSize, state: "completed" }
          }
          return { ...prev, uploadedSize: newUploadedSize }
        }
        return prev
      })
    }, 100)
  }

  const handleRetry = () => {
    setUploadItem(prev => prev ? { ...prev, uploadedSize: 0, state: "uploading" } : null)
    simulateUpload()
  }

  const handleRemove = () => {
    setUploadItem(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const handleSubmit = async () => {
    if (!uploadItem || uploadItem.state !== "completed") return
    try {
      setIsSubmitting(true)
      const formData = new FormData();
      formData.append("csv_file", uploadItem.file);
      //const { status, data } = await postRequest(addBrokerageAgentsPath, formData);
      const { status, data } = await coreClient.post(
        addBrokerageAgentsPath + '/',
        formData,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if(status===201){
        toast.success(data.message);
        queryClient.invalidateQueries({ queryKey: ['brokerage-agents'] }); 
        handleCloseModal();
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        toast.error(`${error.response.data.detail || error.response.data.message}`);
      } else if (error.request) {
          toast.error('No response from server. Please try again later.');
      } else {
          toast.error(`${error.message}`);
      }
    }finally{
      setIsSubmitting(false);
    }
  }

  return (
      <div className="max-w-md mx-auto">
        {!uploadItem && (
          <div 
            className="bg-white rounded-lg p-6 mb-6 border-2 border-dashed border-gray-300 cursor-pointer"
            onClick={() => fileInputRef.current?.click()}
          >
            <div className="flex flex-col items-center">
              <FileUp className="w-9 h-9 text-dark-tremor-background mb-4" />
              <p className="text-sm font-semibold mb-2">Drag and drop a csv file or click to upload</p>
              <p className="text-sm text-gray-500 mb-1">Maximum file size: 1mb</p>
              <p className="text-sm text-gray-500">Supported format: .CSV</p>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept=".csv"
              className="hidden"
            />
          </div>
        )}

        {uploadItem && (
          <UploadStateItem 
            item={uploadItem}
            onRemove={handleRemove}
            onRetry={handleRetry}
          />
        )}

        {uploadItem && uploadItem.state === "completed" && (
          <button
            onClick={handleSubmit}
            disabled={isSubmitting}
            className="w-full bg-dark-tremor-border hover:bg-dark-tremor-border/30 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
          >
            {
                isSubmitting ? <div className='flex justify-center items-center'><SimpleSpinner size={'lg'}/></div> : <span>Submit File</span>
            }
          </button>
        )}
      </div>
  )
}