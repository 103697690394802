


interface CustomTextInputProps {
  label: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputAttrs: {
    name: string;
    placeholder: string;
    type: "text" | "number" | "email" | "password";
    value: string | number;
  };
}

const CustomTextInput = ({ label, inputAttrs, handleChange }: CustomTextInputProps) => {
  return (
    <div className='mt-3'>
      <label htmlFor={inputAttrs.name} className='capitalize'>{label}</label>
      <input
        name={inputAttrs.name}
        placeholder={inputAttrs.placeholder}
        type={inputAttrs.type}
        value={inputAttrs.value}
        onChange={handleChange}
        className='w-full text-[#989093] text-sm font-light py-2 px-2 outline-0 
        rounded-[8px] border border-[#E4E6F1] pl-4 placeholder:text-xsm mt-2 capitalize'
      />
    </div>
  );
}

export default CustomTextInput