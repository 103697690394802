import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { ArrowRight, MoreHorizontal, Package, Rocket, Truck, Scale, CheckCheck } from "lucide-react";
import { FcAcceptDatabase } from "react-icons/fc";
import { FaPersonCircleCheck, FaHouseCircleCheck } from "react-icons/fa6";
import { AiOutlineClose } from 'react-icons/ai'
import { PiUsersFourDuotone } from "react-icons/pi";
import { toast } from "sonner";
import { useQuery } from '@tanstack/react-query';
import { useGetRequest } from "../../hooks/get/useGetRequest";
import { usePostRequest } from "../../hooks/post/usePostRequest";
import { 
  getBrokerAgentsPath, 
  brokerageAcceptedShowings, 
  brokerageCompletedShowings, 
  brokerageCreatedShowings, 
  brokerageDisputedShowings,
  updateBrokerageFlags,
 } from "../../config/api-urls";
import { SimpleSpinner } from "../../components/loaders";
import { pageURL } from "../../components/navigation/pagesURL";
import { PlaceholderCard } from "../../components/skeleton";
import { CustomModal } from "../../components/modal";
import { SettingsBtn } from "../../components/button";
import logo from '../../assets/png/showingxchange-logo.png';
import { LineChartData } from "../../components/graphs";
import { StatsCard } from "../../components/cards";




// interface IStatCardProps {
//   title: string;
//   value: string;
//   icon: React.ReactNode;
//   redirectURL: string;
// }


export default function ManagingBrokerDashboard() {

  const getRequest = useGetRequest();
  const [isChecked, setIsChecked] = useState(false);
  const [isBroadcastChecked, setIsBroadcastChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const postRequest = usePostRequest();
  const navigateTo = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const [isBroadcastModalOpen, setIsBroadcastModalOpen] = useState(false);
  const handleBroadcastOpenModal = () => setIsBroadcastModalOpen(true);
  const handleBroadcastCloseModal = () => setIsBroadcastModalOpen(false);


  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setIsModalOpen(!isChecked);
  };

  const handleCheckboxChange2 = () => {
    setIsBroadcastChecked(!isBroadcastChecked);
    setIsBroadcastModalOpen(!isBroadcastChecked);
  };

  const { isPending: brokerageAgentsRequestLoading, error: brokerageAgentsRequestError, data: brokerageAgentsRequestData } = useQuery({
    queryKey: ['brokerage-agents'],
    queryFn: ()=> getRequest(getBrokerAgentsPath),
    retry: false
  });

  const { isPending: createdRequestLoading, error: createdRequestError, data: createdRequestData } = useQuery({
    queryKey: ['brokerage-created-showings'],
    queryFn: ()=> getRequest(brokerageCreatedShowings),
    retry: false
  });

  const { isPending: acceptedRequestLoading, error: acceptedRequestError, data: acceptedRequestData } = useQuery({
    queryKey: ['brokerage-accepted-showings'],
    queryFn: ()=> getRequest(brokerageAcceptedShowings),
    retry: false
  });

  const { isPending: completedRequestLoading, error: completedRequestError, data: completedRequestData } = useQuery({
    queryKey: ['brokerage-completed-showings'],
    queryFn: ()=> getRequest(brokerageCompletedShowings),
    retry: false
  });

  const { isPending: disputedRequestLoading, error: disputedRequestError, data: disputedRequestData } = useQuery({
    queryKey: ['brokerage-disputed-showings'],
    queryFn: ()=> getRequest(brokerageDisputedShowings),
    retry: false
  });

  const listBrokerageAgents  = brokerageAgentsRequestData?.data?.data || []
  const listAcceptedRequests  = acceptedRequestData?.data?.data || []
  const listCreatedRequests  = createdRequestData?.data?.data || []
  const listCompletedRequests  = completedRequestData?.data?.data || []
  const listDisputedRequests  = disputedRequestData?.data?.data || []
  
  console.log(listCreatedRequests, 'listCreatedRequests')


  const handleUpdateFlagRelatedBrokerages = async()=>{
    try {
      setIsLoading(true);
      const { status, data } = await postRequest(updateBrokerageFlags, {
        related_brokerage: true
      });
      if(status===200){
        toast.success(data.message);
        handleCloseModal();
      }
    } catch (error) {
      console.log(error)
    }finally{
      setIsLoading(false);
    }
  }

  const handleUpdateFlagPublic = async()=>{
    try {
      setIsLoading(true);
      const { status, data } = await postRequest(updateBrokerageFlags, {
        brokerage_broadcast_out: true
      });
      if(status===200){
        toast.success(data.message);
        handleBroadcastCloseModal();
      }
    } catch (error) {
      console.log(error)
    }finally{
      setIsLoading(false);
    }
  }

  const handleRedirect = (url: string)=>{
    navigateTo(url)
  }

  

  const chartdata = [
    {
      date: 'Jan 22',
      'created showings': listCreatedRequests?.length || 0,
    },
    {
      date: 'Feb 22',
      'accepted showings': listAcceptedRequests?.length || 0,
    },
    {
      date: 'Mar 22',
      'completed showings': listCompletedRequests?.length || 0,
    },
    {
      date: 'Mar 22',
      'disputed showings': listDisputedRequests?.length || 0,
    },
  ];

  return (
    <div className="p-6 bg-gray-100 font-primary">
      <h1 className="text-sm font-normal text-navy-900 mb-6">Overview</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <StatsCard 
          redirectURL={pageURL.listBrokerageAgentAcceptedShowings} 
          title="Accepted Showings" 
          value={listAcceptedRequests?.length.toString() || '0'} 
          icon={<FaPersonCircleCheck className="text-customPrimary" />}
        />
        <StatsCard 
          redirectURL={pageURL.listBrokerageAgentCreatedShowings} 
          title="Created Showings" 
          value={listCreatedRequests?.length.toString() || '0'} 
          icon={<FaHouseCircleCheck className="text-customPrimary" />} 
        />
        <StatsCard 
          redirectURL={pageURL.listBrokerageAgentCompletedShowings} 
          title="Completed Showings" 
          value={listCompletedRequests?.length.toString() || '0'} 
          icon={<CheckCheck className="text-customPrimary" />} 
        />
        <StatsCard 
          redirectURL={pageURL.listBrokerageAgentDisputedShowings} 
          title="Disputed Showings" 
          value={listDisputedRequests?.length.toString() || '0'} 
          icon={<Scale className="text-customPrimary" />} 
        />
      </div>
      <div className="w-full flex items-center justify-between gap-5">
        <div className="md:w-1/2 my-5 py-5 px-4 rounded-md shadow-md bg-white">
          <div className="flex items-center justify-between space-x-2 mb-4">
              <label htmlFor={'showForm'} className='capitalize'>Allow showing to be viewed by related brokerages</label>
              <input
              id="showForm"
              checked={isChecked}
              type="checkbox"
              name={'showForm'}
              onChange={handleCheckboxChange}
              className={`toggle border-slate-500 border-2 ${isChecked ? 'bg-customPrimary' : 'bg-slate-500'}`}
              />
          </div>
        </div>
        <div className="md:w-1/2 my-5 py-5 px-4 rounded-md shadow-md bg-white">
          <div className="flex items-center justify-between space-x-2 mb-4">
              <label htmlFor={'broad_cast'} className='capitalize'>Allow showing to be viewed by everyone</label>
              <input
              id="broad_cast"
              checked={isBroadcastChecked}
              type="checkbox"
              name={'broad_cast'}
              onChange={handleCheckboxChange2}
              className={`toggle border-slate-500 border-2 ${isBroadcastChecked ? 'bg-customPrimary' : 'bg-slate-500'}`}
              />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <ListBrokerageAgents 
          listBrokerageAgents={listBrokerageAgents} 
          brokerageAgentsRequestLoading={brokerageAgentsRequestLoading}
        />
        <div className="bg-white rounded-md">
          <LineChartData 
            chartdata={chartdata}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
      </div>

      <CustomModal
            width="w-[300px] md:w-5/12"
            icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
            iconRemove={<AiOutlineClose onClick={handleCloseModal} className="w-6 h-6 text-[#667085]" />}
            title='Set showings available for related broakerages'
            description=''
            bgColor="bg-white"
            textColor="text-black"
            closeModal={handleCloseModal}
            isModalOpen={isModalOpen}
      >
       
        <div className='flex justify-between items-center py-5'>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-whiteSmoke"
            textColor="text-[#000]"
            width="md:w-3/12"
            onClick={handleCloseModal}
        >
          Cancel
        </SettingsBtn>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-customPrimary"
            textColor="text-white"
            width="md:w-3/12"
            onClick={handleUpdateFlagRelatedBrokerages}
        >          
          { isLoading ? <SimpleSpinner size={'sm'}/> : 'Update' }
        </SettingsBtn>
        </div>

      </CustomModal>

      {/* open cue to public */}
      <CustomModal
            width="w-[300px] md:w-5/12"
            icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
            iconRemove={<AiOutlineClose onClick={handleBroadcastCloseModal} className="w-6 h-6 text-[#667085]" />}
            title='Set showings available for everyone'
            description=''
            bgColor="bg-white"
            textColor="text-black"
            closeModal={handleBroadcastCloseModal}
            isModalOpen={isBroadcastModalOpen}
      >
        <div className='flex justify-between items-center py-5'>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-whiteSmoke"
            textColor="text-[#000]"
            width="md:w-3/12"
            onClick={handleBroadcastCloseModal}
        >
          Cancel
        </SettingsBtn>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-customPrimary"
            textColor="text-white"
            width="md:w-3/12"
            onClick={handleUpdateFlagPublic}
        >          
          { isLoading ? <SimpleSpinner size={'sm'}/> : 'Update' }
        </SettingsBtn>
        </div>
      </CustomModal>
    </div>
  )
}

// function StatCard({ title, value, icon, redirectURL }: IStatCardProps ) {
//   const navigateTo = useNavigate();
//   const handleRedirect = (redirectURL: string)=>{
//     navigateTo(redirectURL)
//   };
//   return (
//     <div className="bg-white shadow-md p-4 rounded-lg cursor-pointer" onClick={()=>handleRedirect(redirectURL)}>
//       <p className="text-gray-500 text-sm mb-1">{title}</p>
//       <div className="flex items-center gap-3">
//         <span className="text-sm">{icon}</span>
//         <span className="text-sm font-normal text-navy-900">{value}</span>
//       </div>
//     </div>
//   )
// }

function ListBrokerageAgents({ listBrokerageAgents, brokerageAgentsRequestLoading }: any ) {
  const navigateTo = useNavigate();

  const handleRedirectToDetails = () => {
    navigateTo(pageURL.listBrokerAgents);
};

  return (
    <div className="bg-white shadow-md p-4 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-sm font-normal text-navy-900">Brokerage Agents</h2>
        <button 
        onClick={handleRedirectToDetails}
        className="text-blue-600 text-sm flex items-center">
          Show all
          <ArrowRight className="w-4 h-4 ml-1" />
        </button>
      </div>
      {
        brokerageAgentsRequestLoading 
        ?  <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-1 mt-10">
              {Array.from({ length: 2 }).map((_, i) => (
                  <PlaceholderCard key={i} />
              ))}
          </div>
        : (<>
          {
            listBrokerageAgents && listBrokerageAgents.length > 0 
            ? (
            <>
              <table className="w-full">
              <thead>
                <tr className="text-left text-gray-500 text-sm">
                  <th className="pb-2">Last name</th>
                  <th className="pb-2">Gender</th>
                  <th className="pb-2">Email</th>
                  <th className="pb-2">Status</th>
                </tr>
              </thead>
              <tbody>
                {listBrokerageAgents?.slice(0, 5).map((broker: any, i: number) => (
                  <tr key={broker?.slug} className="text-sm">
                    <td className="py-2">{broker?.first_name}</td>
                    <td className="py-2">{broker?.gender}</td>
                    <td>
                        <span className="badge badge-ghost badge-sm">{broker?.user?.email}</span>
                    </td>
                    <td>
                        <span className={`badge badge-${broker?.activated===false ? 'warning' : 'ghost'} badge-sm`}>{broker?.activated===false ? 'Inactive' : 'Active'}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </>
            ) 
            : (
            <div className="h-full flex justify-center flex-col gap-3  items-center">
              <PiUsersFourDuotone className="w-9 h-9 text-customPrimary" />
              <h3 className="text-sm">No brokerage agents Created Yet</h3>
            </div>
            )
          }
        </>) 
      }
    </div>
  )
}


