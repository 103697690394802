
import coreClient from '../services/coreApi';
import { baseURL } from "../services/baseURL.service";


interface IPostResponse {
  status: number;
  data?: any;
  error?: string;
}

export const useDeleteRequest = () => {
  const deleteRequest = async (endPoint: string, slug: any) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const response = await coreClient.delete(`${baseURL}/${endPoint}/${slug}/`);
      return { status: response.status, data: response.data } as IPostResponse;
    } catch (error) {
      throw error
    }
  };

  return deleteRequest;
};
