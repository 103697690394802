
import coreClient from '../../services/coreApi';
import { baseURL } from "../../services/baseURL.service";

interface IListDisputesResponse {
  status: number;
  data?: any;
  error?: string;
}

export const useGetDisputes = () => {
  const listDisputes = async () => {
    try {
      const response = await coreClient.get(`${baseURL}/list-billing-disputes/`);
      return { status: response.status, data: response.data } as IListDisputesResponse;
    } catch (error) {
      throw error
    }
  };

  return listDisputes;
};
