
import coreClient from '../../services/coreApi';
import { baseURL } from "../../services/baseURL.service";

interface IGetRequestResponse {
  status: number;
  data?: any;
  error?: string;
}

export const useGetRequest = () => {
  const getRequest = async (endpoint:string) => {
    try {
      const response = await coreClient.get(`${baseURL}/${endpoint}/`);
      return { status: response.status, data: response.data } as IGetRequestResponse;
    } catch (error) {
      throw error
    }
  };

  return getRequest;
};
