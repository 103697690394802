
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Eye, UserCheck, CarTaxiFront, CarIcon, Scale, ShieldCheck, UserPlus, CheckCheck } from 'lucide-react';
import { FaPersonCircleCheck, FaHouseCircleCheck } from "react-icons/fa6";
import { AiOutlineClose } from 'react-icons/ai'
import { FaIdCard } from "react-icons/fa";
import { toast } from 'sonner';
import { ModalDialog, CustomModal } from '../modal';
import { SimpleSpinner } from '../loaders';
import { COLORS } from '../../utils/constants/colors';
import { SettingsBtn } from '../button';
import logo from '../../assets/png/showingxchange-logo.png';
import { StatsCardB } from '.';
import { pageURL } from '../navigation/pagesURL';


interface IBrokerageAgentProfileCardProps {
  userSlug: string
  isLoading: boolean
  agentDetails: any,
  handleOpenModal: ()=> void,
  handleReActivateAgent: ()=> void,
  handleDeactivateAgent: ()=> void,
  handleDeleteAgent: ()=> void,
  listAgentCreatedRequests: any
  listAgentAcceptedRequests: any 
  listAgentCompletedRequests: any
  listAgentConfirmedRequests: any
  listAgentDisputedRequests: any 
}

const BrokerageAgentProfileCard = ({ 
    userSlug,
    isLoading, 
    agentDetails, 
    handleReActivateAgent, 
    handleDeactivateAgent, 
    handleDeleteAgent, 
    listAgentCreatedRequests,
    listAgentAcceptedRequests, 
    listAgentCompletedRequests,
    listAgentConfirmedRequests,
    listAgentDisputedRequests,
}: IBrokerageAgentProfileCardProps ) => {

  const [profileImagePreview, setProfileImagePreview] = useState(false);
  const [frontDriversLicensePreview, setFrontDriversLicensePreview] = useState(false);
  const [backDriversLicensePreview, setBackDriversLicensePreview] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const [isDeactivateModalOpen, setDeactivateIsModalOpen] = useState(false);
  const handleOpenDeactivateModal = () => setDeactivateIsModalOpen(true);
  const handleCloseDeactivateModal = () => setDeactivateIsModalOpen(false);

  const [isDeleteModalOpen, setDeleteIsModalOpen] = useState(false);
  const handleOpenDeleteModal = () => setDeleteIsModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteIsModalOpen(false);

  const navigateTo = useNavigate();

  const handleRedirect = (type: string)=>{
    navigateTo(pageURL.listSingleBrokerageAgentShowings, { state: {
        type: type,
        userSlug: userSlug,
    }})
  }


  const handleToggleProfileImagePreview = ()=>{
    setProfileImagePreview(!profileImagePreview);
  }
  const handleToggleFrontDriversLicensePreview = ()=>{
    setFrontDriversLicensePreview(!frontDriversLicensePreview);
  }
  const handleToggleBacktDriversLicensePreview = ()=>{
      setBackDriversLicensePreview(!backDriversLicensePreview);
  }


  return (
    <div className='h-full w-full font-primary'>
        <div className='w-full md:h-32 px-5 md:px-10 flex items-center justify-between bg-customPrimar text-whit border-b'>
            <div className='flex items-center gap-4 '>
                <div className=" w-24 h-24 border-2 border-customPrimary p-1 rounded-full">
                  {
                    agentDetails?.upload_picture 
                    ? (
                      <img src={agentDetails?.upload_picture} alt="agent profile" className='h-full w-full rounded-full'/>
                    ) 
                    :
                    (
                     <div className='h-full flex justify-center items-center'>
                      <UserPlus size={40} className='text-customPrimary' />
                     </div>
                    )
                  }
                </div>
                <div className=''>
                    <h3 className='font-normal capitalize'>{agentDetails?.first_name} {agentDetails?.last_name}</h3>
                    <h3>{agentDetails?.agent_country}</h3>
                    <h3 className='text-xsm'>status: <span className='rounded-full p-1 border px-5 text-xxsm'>{agentDetails?.user?.is_application_approved}</span></h3>
                </div>
            </div>
            <div className='w-6/12 flex justify-end gap-3'>
                {
                    agentDetails?.activated===false 
                    ? (
                        <SettingsBtn
                            isBorder={false}
                            bgColor="bg-customPrimary"
                            textColor="text-white"
                            width="md:w-3/12"
                            onClick={handleOpenModal}
                        >
                            Activate Agent
                        </SettingsBtn>
                    ) 
                    : (
                        <SettingsBtn
                            isBorder={false}
                            bgColor="bg-customPrimary"
                            textColor="text-white"
                            width="md:w-3/12"
                            onClick={handleOpenDeactivateModal}
                        >
                            Deactivate Agent
                        </SettingsBtn>
                    )
                }
                <SettingsBtn
                    isBorder={false}
                    bgColor="bg-red-500"
                    textColor="text-white"
                    width="md:w-3/12"
                    onClick={handleOpenDeleteModal}
                >
                    Delete Agent
                </SettingsBtn>
            </div>
            
        </div>
        <div className='h-full flex'>
            <div className='w-4/12 border-r p-5'>
                <h3 className='my-5 font-normal flex items-center gap-3'> <span><UserCheck color={COLORS.primary} /></span>Personal information</h3>
                <ul className='flex flex-col gap-3 list-disc px-5'>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>first name</span>
                        <h3 className='font-normal'>{agentDetails?.first_name}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>middle name</span>
                        <h3 className='font-normal'>{agentDetails?.middle_name}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>last name</span>
                        <h3 className='font-normal'>{agentDetails?.last_name}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>date of birth</span>
                        <h3 className='font-normal'>{agentDetails?.date_of_birth}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>Address</span>
                        <h3 className='font-normal'>{agentDetails?.agent_address1}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>City</span>
                        <h3 className='font-normal'>{agentDetails?.agent_state}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>state</span>
                        <h3 className='font-normal'>{agentDetails?.agent_state}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>zip code</span>
                        <h3 className='font-normal'>{agentDetails?.zip_code}</h3>
                    </li>
                    <li className=''>
                        <span className='text-slate-500 capitalize'>country</span>
                        <h3 className='font-normal'>{agentDetails?.agent_country}</h3>
                    </li>
                    <li className='flex items-center justify-between'>
                        <span className='text-slate-500 capitalize'>Profile picture</span>
                        {/* <h3 className='font-normal'>USA</h3> */}
                        <button
                        onClick={handleToggleProfileImagePreview} 
                        className='flex items-center gap-3 text-white bg-customPrimary px-3 py-1 rounded-full capitalize text-xsm'>
                            preview <span><Eye size={15} color='white' /></span>
                        </button>
                    </li>
                </ul>
            </div>
            <div className='w-8/12 min-h-full overflow-y-auto'>
                {/* <div className='border-b p-5'>
                    <h3 className='my-5 font-normal flex items-center gap-3'> <span><CarIcon color={COLORS.primary} /></span>Drivers Licence Information</h3>
                    <ul className='grid grid-cols-3 gap-2  px-5'>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>Drivers Licence Number</span>
                            <h3 className='font-normal'>{agentDetails?.drivers_licence_number}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>Issuing State</span>
                            <h3 className='font-normal'>{agentDetails?.issuing_state}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>Issuing Date</span>
                            <h3 className='font-normal'>{agentDetails?.issue_date}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>Expiry Date</span>
                            <h3 className='font-normal'>{agentDetails?.expiring_date}</h3>
                        </li>
                    
                        <li className='flex items-center justify-between'>
                            <span className='text-slate-500 capitalize'>Drivers Licence (Front)</span>
                            <button
                            onClick={handleToggleFrontDriversLicensePreview} 
                            className='flex items-center gap-3 text-white bg-customPrimary px-3 py-1 rounded-full capitalize text-xsm'>
                                preview <span><Eye size={15} color='white' /></span>
                            </button>
                        </li>
                        <li className='flex items-center justify-between'>
                            <span className='text-slate-500 capitalize'>Drivers Licence (Back)</span>
                            <button
                            onClick={handleToggleBacktDriversLicensePreview} 
                            className='flex items-center gap-3 text-white bg-customPrimary px-3 py-1 rounded-full capitalize text-xsm'>
                                preview <span><Eye size={15} color='white' /></span>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className='border-b p-5'>
                    <h3 className='my-5 font-normal flex items-center gap-3'> <span><Scale color={COLORS.primary} /></span>Real Estate License information</h3>
                    <ul className='grid grid-cols-3 gap-2  px-5'>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>managing broker first_name:</span>
                            <h3 className='font-normal'>{agentDetails?.managing_broker_first_name}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>rmanaging broker middle name:</span>
                            <h3 className='font-normal'>{agentDetails?.managing_broker_middle_name}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>managing broker last name:</span>
                            <h3 className='font-normal'>{agentDetails?.managing_broker_last_name}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage company name:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_company_name}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage address1:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_address1}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage address2:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_address2}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage city:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_city}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage state:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_state}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage zip_code:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_zip_code}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>brokerage country:</span>
                            <h3 className='font-normal'>{agentDetails?.brokerage_country}</h3>
                        </li>
                    </ul>
                </div> */}
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mb-8 px-5">
                    <StatsCardB 
                        redirectFn={()=>handleRedirect('accepted')} 
                        title="Accepted Showings" 
                        value={listAgentAcceptedRequests?.length.toString() || '0'} 
                        icon={<FaPersonCircleCheck className="text-customPrimary" />}
                    />
                    <StatsCardB 
                        redirectFn={()=>handleRedirect('created')} 
                        title="Created Showings" 
                        value={listAgentCreatedRequests?.length.toString() || '0'} 
                        icon={<FaHouseCircleCheck className="text-customPrimary" />} 
                    />
                    <StatsCardB 
                        redirectFn={()=>handleRedirect('completed')} 
                        title="Completed Showings" 
                        value={listAgentCompletedRequests?.length.toString() || '0'} 
                        icon={<CheckCheck className="text-customPrimary" />} 
                    />
                    <StatsCardB 
                        redirectFn={()=>handleRedirect('disputed')} 
                        title="Disputed Showings" 
                        value={listAgentDisputedRequests?.length.toString() || '0'} 
                        icon={<Scale className="text-customPrimary" />} 
                    />
                </div>
                <div className='border-b p-5'>
                    <h3 className='my-5 font-normal flex items-center gap-3'> <span><Scale color={COLORS.primary} /></span>Brokerage information</h3>
                    <ul className='grid grid-cols-3 gap-2  px-5'>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>real estate licence state:</span>
                            <h3 className='font-normal'>{agentDetails?.real_estate_licence_state}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>real estate licence number:</span>
                            <h3 className='font-normal'>{agentDetails?.real_estate_licence_number}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>real estate licence expiry date:</span>
                            <h3 className='font-normal'>{agentDetails?.real_estate_licence_expiry_date}</h3>
                        </li>
                        <li className=''>
                            <span className='text-slate-500 capitalize'>real estate licence status:</span>
                            <h3 className='font-normal'>{agentDetails?.real_estate_licence_status}</h3>
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>

        <ModalDialog 
            modalOpen={profileImagePreview}
            modalClose={handleToggleProfileImagePreview}
        >
            <div className='mt-10'>
                {
                    agentDetails?.upload_picture 
                    ? (
                        <img src={agentDetails?.upload_picture} alt="agent profile" className='w-[35rem] h-[32rem] object-cover' />
                    ) 
                    : (
                        <div className='h-full flex flex-col gap-5 justify-center items-center'>
                            <UserPlus size={100} className='text-customPrimary' />
                            <h3 className='text-sm text-center'>No Profile Picture added yet <br /> for this agent</h3>
                        </div>
                    ) 
                }
            </div>
        </ModalDialog>
        <ModalDialog 
            modalOpen={frontDriversLicensePreview}
            modalClose={handleToggleFrontDriversLicensePreview}
        >
            <div className='mt-10'>
                {
                    agentDetails?.front_drivers_licence_picture 
                    ? (
                        <img src={agentDetails?.front_drivers_licence_picture} alt="agent profile" className='w-[35rem] h-[32rem] object-cover' />
                    ) 
                    : (
                        <div className='h-full flex flex-col gap-5 justify-center items-center'>
                            <FaIdCard size={100} className='text-customPrimary' />
                            <h3 className='text-sm text-center'>No Drivers License added yet <br /> for this agent</h3>
                        </div>
                    ) 
                }
            </div>
        </ModalDialog>
        <ModalDialog 
            modalOpen={backDriversLicensePreview}
            modalClose={handleToggleBacktDriversLicensePreview}
        >
            <div className='mt-10'>
                {
                    agentDetails?.back_drivers_licence_picture 
                    ? (
                        <img src={agentDetails?.back_drivers_licence_picture} alt="agent profile" className='w-[35rem] h-[32rem] object-cover' />
                    ) 
                    : (
                        <div className='h-full flex flex-col gap-5 justify-center items-center'>
                            <FaIdCard size={100} className='text-customPrimary' />
                            <h3 className='text-sm text-center'>No Drivers License added yet <br /> for this agent</h3>
                        </div>
                    ) 
                }
            </div>
        </ModalDialog>
        <CustomModal
            width="w-[300px] md:w-5/12"
            icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
            iconRemove={<AiOutlineClose onClick={handleCloseModal} className="w-6 h-6 text-[#667085]" />}
            title={`Activate ${agentDetails?.first_name} ${agentDetails?.last_name}`}
            description={`You will be required to make a payment to activate this user`}
            bgColor="bg-white"
            textColor="text-black"
            closeModal={handleCloseModal}
            isModalOpen={isModalOpen}
      >
       
        <div className='flex justify-between items-center border-t py-5'>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-whiteSmoke"
            textColor="text-[#000]"
            width="md:w-3/12"
            onClick={handleCloseModal}
        >
          Cancel
        </SettingsBtn>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-customPrimary"
            textColor="text-white"
            width="md:w-3/12"
            onClick={handleReActivateAgent}
        >          
          { isLoading ? <SimpleSpinner size={'sm'}/> : 'Activate Agent' }
        </SettingsBtn>
        </div>

      </CustomModal>

      {/* deactivate modal */}
      <CustomModal
            width="w-[300px] md:w-5/12"
            icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
            iconRemove={<AiOutlineClose onClick={handleCloseDeactivateModal} className="w-6 h-6 text-[#667085]" />}
            title={`Deactivate ${agentDetails?.first_name} ${agentDetails?.last_name}`}
            description={`You will be required to make a payment to activate this user on reactivation`}
            bgColor="bg-white"
            textColor="text-black"
            closeModal={handleCloseDeactivateModal}
            isModalOpen={isDeactivateModalOpen}
      >
       
        <div className='flex justify-between items-center border-t py-5'>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-whiteSmoke"
            textColor="text-[#000]"
            width="md:w-3/12"
            onClick={handleCloseDeactivateModal}
        >
          Cancel
        </SettingsBtn>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-customPrimary"
            textColor="text-white"
            width="md:w-3/12"
            onClick={handleDeactivateAgent}
        >          
          { isLoading ? <SimpleSpinner size={'sm'}/> : 'Deactivate Agent' }
        </SettingsBtn>
        </div>

      </CustomModal>

      {/* delete modal */}
      <CustomModal
            width="w-[300px] md:w-5/12"
            icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
            iconRemove={<AiOutlineClose onClick={handleCloseDeleteModal} className="w-6 h-6 text-[#667085]" />}
            title={`Delete ${agentDetails?.first_name} ${agentDetails?.last_name}`}
            description={`You are about to delete this user`}
            bgColor="bg-white"
            textColor="text-black"
            closeModal={handleCloseDeleteModal}
            isModalOpen={isDeleteModalOpen}
      >
       
        <div className='flex justify-between items-center border-t py-5'>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-whiteSmoke"
            textColor="text-[#000]"
            width="md:w-3/12"
            onClick={handleCloseDeleteModal}
        >
          Cancel
        </SettingsBtn>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-customPrimary"
            textColor="text-white"
            width="md:w-3/12"
            onClick={handleDeleteAgent}
        >          
          { isLoading ? <SimpleSpinner size={'sm'}/> : 'Delete Agent' }
        </SettingsBtn>
        </div>

      </CustomModal>
    </div>
  )
}

export default BrokerageAgentProfileCard