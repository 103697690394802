
import React, { useState } from 'react'
import DatePicker from 'react-datepicker';
import { toast } from 'sonner';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePostRequest } from '../../../hooks/post/usePostRequest';
import CustomTextInput from '../inputs/CustomTextInput';
import { SettingsBtn } from '../../button';
import { createGeneralCoupon } from '../../../config/api-urls';
import { SimpleSpinner } from '../../loaders';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../../ui/Select';

interface ICouponcodeForm {
    valid_from: Date | null,
    valid_to: Date | null,
    coupon_percent: number,
    description: string,
    coupon_applicable: string,
    max_use_number: number,
}

type TApplicableCoupons = 'Showing Request Coupon' | 'Application Coupon' | 'General Coupon'

const CreateCouponCodeForm = ({ closeModal}: { closeModal: Function}) => {
    const postRequest = usePostRequest();
    const queryClient = useQueryClient();
    const [formData, setFormData] = useState<ICouponcodeForm>({
        valid_from: null,
        valid_to: null,
        coupon_percent: 0,
        description: "",
        coupon_applicable: "",
        max_use_number: 0
    });
    const applicableCoupons = ['Showing Request Coupon', 'General Coupon', 'Application Coupon']

    const [errors, setErrors] = useState<Partial<Record<keyof ICouponcodeForm, string>>>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const handleStartDateChange = (date: Date | null) => {
        setFormData((prevState) => ({
            ...prevState,
            valid_from: date,
        }));
        setErrors((prev) => ({ ...prev, valid_from: "" }));
    };

    const handleEndDateChange = (date: Date | null) => {
        setFormData((prevState) => ({
            ...prevState,
            valid_to: date,
        }));
        setErrors((prev) => ({ ...prev, valid_to: "" }));
    };

    const handleSelectCoupon = (value: TApplicableCoupons)=>{
        setFormData((prev)=>({
            ...prev,
            coupon_applicable: value
        }))
    }

    const placeholders = {
        valid_from: "2024-09-14",
        valid_to: "2024-11-19",
        coupon_percent: 50,
        description: "testing",
        coupon_applicable: "Showing Request Coupon",
        max_use_number: 500
    };

    const mutation = useMutation({
        mutationFn: (formData: any) => postRequest(createGeneralCoupon, formData),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['coupon-codes'] });
            closeModal();
        },
        onError: (error: any) => {
            toast.error(error.response.data.message)
        },
    });

    const isLoading = mutation.isPending

    const validateForm = () => {
        const newErrors: Partial<Record<keyof ICouponcodeForm, string>> = {};

        if (!formData.valid_from) newErrors.valid_from = "Start date is required";
        if (!formData.valid_to) newErrors.valid_to = "End date is required";
        if (!formData.coupon_percent) newErrors.coupon_percent = "Coupon percent is required";
        if (!formData.description) newErrors.description = "Description is required";
        if (!formData.coupon_applicable) newErrors.coupon_applicable = "Applicable field is required";
        if (!formData.max_use_number) newErrors.max_use_number = "Max use number is required";

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return false;
        }

        if (formData.valid_from && formData.valid_to && formData.valid_from > formData.valid_to) {
            toast.error('Start date cannot be greater than end date');
            return false;
        }

        return true;
    };

    const handleSubmitCouponCodeForm = () => {
        if (!validateForm()) return;

        const validFrom = formData.valid_from ? format(formData.valid_from, 'yyyy-MM-dd') : null;
        const validTo = formData.valid_to ? format(formData.valid_to, 'yyyy-MM-dd') : null;

        const payload = {
            valid_from: validFrom,
            valid_to: validTo,
            "is_general": true,
            coupon_percent: formData.coupon_percent,
            description: formData.description,
            coupon_applicable: formData.coupon_applicable,
            max_use_number: formData.max_use_number
        };
         mutation.mutate(payload);
       
    };

    return (
        <div className='mt-5 font-primary'>
            <div className='grid md:grid-cols-2 gap-3'>
                <div className='w-full mt-3'>
                    <h2 className='mb-2'>select coupon type</h2>
                <Select onValueChange={handleSelectCoupon}>
                    <SelectTrigger className="w-full h-11">
                        <SelectValue placeholder="Select Coupon Type" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                        <SelectLabel>Copon type</SelectLabel>
                        {
                            applicableCoupons.map((type, index)=>(
                                <SelectItem 
                                key={index}
                                value={type} 
                                className='cursor-pointer'
                                >
                                    {type}
                                </SelectItem>
                            ))
                        }
                        </SelectGroup>
                    </SelectContent>
                </Select>
                {errors.coupon_applicable && <span className='text-red-600'>{errors.coupon_applicable}</span>}
                </div>
                <div>
                    <CustomTextInput
                        label='coupon_percent'
                        handleChange={handleChange}
                        inputAttrs={{
                            name: 'coupon_percent',
                            placeholder: placeholders.coupon_percent.toString(),
                            type: 'number',
                            value: formData.coupon_percent,
                        }}
                    />
                    {errors.coupon_percent && <span className='text-red-600'>{errors.coupon_percent}</span>}
                </div>
                <div>
                    <CustomTextInput
                        label='max_use_number'
                        handleChange={handleChange}
                        inputAttrs={{
                            name: 'max_use_number',
                            placeholder: placeholders.max_use_number.toString(),
                            type: 'number',
                            value: formData.max_use_number,
                        }}
                    />
                    {errors.max_use_number && <span className='text-red-600'>{errors.max_use_number}</span>}
                </div>
                <div>
                    <CustomTextInput
                        label='description'
                        handleChange={handleChange}
                        inputAttrs={{
                            name: 'description',
                            placeholder: placeholders.description,
                            type: 'text',
                            value: formData.description,
                        }}
                    />
                    {errors.description && <span className='text-red-600'>{errors.description}</span>}
                </div>
                <div className='mt-1'>
                    <h2 className='my-2'>select start date for coupon</h2>
                    <div className='w-full p-2 border rounded-md'>
                        <DatePicker
                            selected={formData?.valid_from}
                            onChange={handleStartDateChange}
                            dateFormat="MMM d, yyyy"
                            minDate={new Date()}
                            isClearable
                            placeholderText="From"
                            className='w-full outline-0'
                        />
                    </div>
                    {errors.valid_from && <span className='text-red-600'>{errors.valid_from}</span>}
                </div>
                <div className='mt-1'>
                    <h2 className='my-2'>select expiry date for coupon</h2>
                    <div className='w-full p-2 border rounded-md'>
                        <DatePicker
                            selected={formData?.valid_to}
                            onChange={handleEndDateChange}
                            dateFormat="MMM d, yyyy"
                            minDate={new Date()}
                            isClearable
                            placeholderText="Expiry date"
                            className='w-full outline-0'
                        />
                    </div>
                    {errors.valid_to && <span className='text-red-600'>{errors.valid_to}</span>}
                </div>
                {/* <div></div> */}
                <SettingsBtn
                    isBorder={false}
                    bgColor="bg-customPrimary"
                    textColor="text-white"
                    width="w-full"
                    onClick={handleSubmitCouponCodeForm}
                >
                    {
                        isLoading ? <SimpleSpinner size='sm'/> : <span>Create coupon code</span>
                    }
                </SettingsBtn>
            </div>
        </div>
    );
};

export default CreateCouponCodeForm;
