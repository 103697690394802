import { PaymentSuccessCard } from '../../components/cards'

const CreateRequestSuccess = () => {
  return (
    <PaymentSuccessCard
    message='payment was successful'
  />
  )
}

export default CreateRequestSuccess