import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'sonner';
import { useGetAgentDetails } from '../../hooks/get/useGetAgentDetails';
import { AgentProfileCard, AdminProfileCard } from '../../components/cards';
import { useGetRequestDetails } from '../../hooks/get/useGetRequestDetails';

const ProfileDetails = () => {
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null);
  const { slug } = useParams();
  const location = useLocation();
  const userType = location.state
  const getAgentDetails = useGetAgentDetails();
  const getRequestDetails = useGetRequestDetails();

  const userDetailsEndPoint = userType === 'operation' ?
  'get-operation-application-details' 
  : userType === 'billing' 
  ? 'get-billing-application-details' 
  : 'get-agent-application-details'


  useEffect(()=>{
    (async function() {
      try {
        setLoadingDetails(true);
        //const { status, data } = await getAgentDetails(slug);
        const { status, data } = await getRequestDetails(userDetailsEndPoint, slug);
        if(status===200){
          setAgentDetails(data.data);
        }
      } catch (error) {
        toast.error('an error occured')
      }finally{
        setLoadingDetails(false);
      }
    })()
  }, [slug])

  if(loadingDetails){
    return <h2>loading details</h2>
  }

  function renderUserCard() {
    switch (userType) {
      case 'operation':
      case 'billing':
        return (
          <AdminProfileCard agentDetails={agentDetails} userType={userType} />
        );
      default:
        return <AgentProfileCard agentDetails={agentDetails} />;
    }
  }
  return (
    <div className='h-screen'>
      {
        renderUserCard()
      }
    </div>
  )
}

export default ProfileDetails