
import coreClient from '../../services/coreApi';
import { baseURL } from "../../services/baseURL.service";

interface IListAssignedResponse {
  status: number;
  data?: any;
  error?: string;
}

export const useListAssignedDisputes = () => {
  const listAssignedDisputes = async () => {
    try {
      const response = await coreClient.get(`${baseURL}/list-assigned-disputes/`);
      return { status: response.status, data: response.data } as IListAssignedResponse;
    } catch (error) {
      throw error
    }
  };

  return listAssignedDisputes;
};
