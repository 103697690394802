
import coreClient from '../../services/coreApi';
import { baseURL } from "../../services/baseURL.service";


interface IPostResponse {
  status: number;
  data?: any;
  error?: string;
}

export const usePostRequestSlug = () => {
  const postRequestSlug = async (endPoint: string, slug: string, payload: any) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const response = await coreClient.post(`${baseURL}/${endPoint}/${slug}/`, payload);
      return { status: response.status, data: response.data } as IPostResponse;
    } catch (error) {
      throw error
    }
  };

  return postRequestSlug;
};
