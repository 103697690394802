import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { useGetRequest } from '../../../hooks/get/useGetRequest';
import { usersNoShowingsPath } from '../../../config/api-urls';
import { PlaceholderCard } from '../../../components/skeleton';
import { NoData } from '../../../components/placeholders';
import { ItemSearch } from '../../../components/search';

const AgentsNoShowings = () => {
    const getRequest = useGetRequest();
    const [searchQuery, setSearchQuery] = useState<string>('');


    const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const { isPending, error, data } = useQuery({
        queryKey: ['user-no-showings'],
        queryFn: ()=> getRequest(usersNoShowingsPath),
        retry: false,
        refetchOnWindowFocus: false,
    });

    const listusersNoShowings  = data?.data?.data || []

    console.log(listusersNoShowings, 'listusersNoShowings==>')

    const filteredQuery = Array.isArray(listusersNoShowings)
        ? listusersNoShowings.filter((user: any) => {
            const result = `${user?.email} ${user?.phone_number}`;
            return result.toLowerCase().includes(searchQuery.toLowerCase());
        })
        : [];

    return (
        <div className='font-primary'>
            {isPending ? (
                <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-1 mt-10">
                    {Array.from({ length: 8 }).map((_, i) => (
                        <PlaceholderCard key={i} />
                    ))}
                </div>
            ) : (
                <>
                    {listusersNoShowings && listusersNoShowings.length > 0 ? (
                        <div className='bg-white rounded-md shadow-lg mx-3 p-5 mt-5'>
                            <div className='flex items-center justify-between border-b pb-3'>
                                <h3 className=' text-xsm'>Total number of users <strong>({listusersNoShowings.length})</strong></h3>
                            </div>
                         
                            <ItemSearch
                                searchQuery={searchQuery}
                                handleSearchQuery={handleSearchQuery}
                            />
                            <div className='h-auto my-5 overflow-y-auto'>
                                <div className='overflow-y-auto'>
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>S/N</th>
                                                {/* <th>First Name</th>
                                                <th>Last Name</th> */}
                                                <th>Email</th>
                                                <th>Phone Number</th>
                                                {/* <th>Country</th>
                                                <th>State</th>
                                                <th>offer</th>
                                                <th>Showing Date</th> */}
                                                <th>Time</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredQuery.length > 0 ? (
                                                filteredQuery.map((user: any, i: number) => (
                                                    <tr
                                                        key={i}
                                                        className=''
                                                    >
                                                        <td>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    className="checkbox checked:border-[#3EB489] [--chkbg:#808080] [--chkfg:#fff]"
                                                                />
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <div className="w-9 h-9 border border-slate-400 rounded-full flex justify-center items-center">
                                                                <strong>{i + 1}</strong>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{user?.email}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{user?.phone}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{user?.timestamp}</span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={8} className="text-center">
                                                        No result found for <strong>{searchQuery}</strong>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='h-screen flex flex-col gap-5 justify-center items-center'>
                            <NoData icon={<Search size={30} />}>
                                <h3 className='text-normal'>No Accepted Showings Found</h3>
                            </NoData>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default AgentsNoShowings;
