import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { Search, UserPlus, ChevronsRight } from 'lucide-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { AiOutlineClose } from 'react-icons/ai'
import { useGetRequest } from '../../../hooks/get/useGetRequest';
import { usePostRequest } from '../../../hooks/post/usePostRequest';
import { activateBrokerageAgent, getBrokerAgentsPath } from '../../../config/api-urls';
import { PlaceholderCard } from '../../../components/skeleton';
import { NoData } from '../../../components/placeholders';
import { SettingsBtn } from '../../../components/button';
import CustomModal from '../../../components/modal/CustomModal';
import logo from '../../../assets/png/showingxchange-logo.png';
import { ItemSearch } from '../../../components/search';
import CsvFileUpload from '../../../components/forms/file-upload/CsvFileUpload';
import { pageURL } from '../../../components/navigation/pagesURL';
import { SimpleSpinner } from '../../../components/loaders';

const ListBrokerAgents = () => {
    const getRequest = useGetRequest();
    const postRequest = usePostRequest();
    const queryClient = useQueryClient();
    const navigateTo = useNavigate();

    const [isLoading, setIstLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedBrokers, setSelectedBrokers] = useState<any[]>([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const [isActivateBrokerModalOpen, setIsActivateBrokerModalOpen] = useState(false);
    const handleOpenActivateBrokerModal = () => setIsActivateBrokerModalOpen(true);
    const handleCloseActivateBrokerModal = () => setIsActivateBrokerModalOpen(false);

    const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const { isPending, error, data } = useQuery({
        queryKey: ['brokerage-agents'],
        queryFn: ()=> getRequest(getBrokerAgentsPath),
        retry: false
    });

    const listBrokerageAgents  = data?.data?.data || []

    const filteredQuery = Array.isArray(listBrokerageAgents)
        ? listBrokerageAgents.filter((broker: any) => {
            const result = `${broker?.email} ${broker?.phone_number}`;
            return result.toLowerCase().includes(searchQuery.toLowerCase());
        })
        : [];

    // Handle select or deselect broker
    const handleSelectBroker = (broker: any) => {
        setSelectedBrokers((prevSelected) => {
            if (prevSelected.some((item) => item.slug === broker.slug)) {
                return prevSelected.filter((item) => item.slug !== broker.slug);
            } else {
                return [...prevSelected, broker];
            }
        });
    };

    // Check if a broker is selected
    const isBrokerSelected = (broker: any) => {
        return selectedBrokers.some((item) => item.slug === broker.slug);
    };

    const handleActivateAgent = async()=>{
        const selectedBrokersPayload = selectedBrokers.map((broker)=> broker.slug);
        const payload = {
            agent_application_slug_list: selectedBrokersPayload,
        }

        console.log(payload, 'payload')
        setIstLoading(true);
        try {
          const { status, data } = await postRequest(activateBrokerageAgent, payload);
          if(status===201){
            toast.success(data.message);
            window.location.href = data.data.link
            queryClient.invalidateQueries({ queryKey: ['brokerage-agents'] }); 
          }
        } catch (error: any) {
          if (error.response && error.response.data && error.response.data) {
            toast.error(`${error.response.data.message}`);
          } else if (error.request) {
              toast.error('No response from server. Please try again later.');
          } else {
              toast.error(`${error.message}`);
          }
        }finally{
          setIstLoading(false);
        }
      }

    const handleRedirectToDetails = (slug: string) => {
        navigateTo(pageURL.brokerageAgentDetails + '/' + slug);
    };

    return (
        <div className='font-primary'>
            {isPending ? (
                <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-1 mt-10">
                    {Array.from({ length: 8 }).map((_, i) => (
                        <PlaceholderCard key={i} />
                    ))}
                </div>
            ) : (
                <>
                    {listBrokerageAgents && listBrokerageAgents.length > 0 ? (
                        <div className='bg-white rounded-md shadow-lg mx-3 p-5 mt-5'>
                            <div className='flex justify-end gap-3'>
                                {
                                    selectedBrokers.length > 0 && (
                                        <SettingsBtn
                                            isBorder={false}
                                            bgColor="bg-[#000]"
                                            textColor="text-white"
                                            width="md:w-3/12"
                                            onClick={handleOpenActivateBrokerModal}
                                        >
                                            Activate Selected Agents
                                        </SettingsBtn>
                                    )
                                }
                                <SettingsBtn
                                    isBorder={false}
                                    bgColor="bg-customPrimary"
                                    textColor="text-white"
                                    width="md:w-2/12"
                                    onClick={handleOpenModal}
                                >
                                    import New Brokers
                                </SettingsBtn>
                            </div>
                            <div className='flex items-center justify-between border-b pb-3'>
                                <h3 className=' text-xsm'>Number of Brokerage Agents <strong>({listBrokerageAgents.length})</strong></h3>
                            </div>
                         
                            <ItemSearch
                                searchQuery={searchQuery}
                                handleSearchQuery={handleSearchQuery}
                            />
                            <div className='h-auto my-5 overflow-y-auto'>
                                <div className='overflow-y-auto'>
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>S/N</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Gender</th>
                                                <th>Email</th>
                                                <th>Phone Number</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredQuery.length > 0 ? (
                                                filteredQuery.map((broker: any, i: number) => (
                                                    <tr
                                                        key={i}
                                                        className={isBrokerSelected(broker) ? 'bg-whiteSmoke' : ''}
                                                    >
                                                        <td>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    className="checkbox checked:border-[#3EB489] [--chkbg:#808080] [--chkfg:#fff]"
                                                                    onChange={() => handleSelectBroker(broker)}
                                                                    checked={isBrokerSelected(broker)}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <div className="w-9 h-9 border border-slate-400 rounded-full flex justify-center items-center">
                                                                <strong>{i + 1}</strong>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{broker?.first_name}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{broker?.last_name}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{broker?.gender}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{broker?.user?.email}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-ghost badge-sm">{broker?.user?.phone_number}</span>
                                                        </td>
                                                        <td>
                                                            <span className={`badge badge-${broker?.activated===false ? 'warning' : 'ghost'} badge-sm`}>{broker?.activated===false ? 'Inactive' : 'Active'}</span>
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="flex items-center gap-2 bg-[#000]/50 font-normal rounded-md px-2 py-1 text-white"
                                                                onClick={() => handleRedirectToDetails(broker?.slug)}
                                                            >
                                                                Details <ChevronsRight size={20} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={8} className="text-center">
                                                        No result found for <strong>{searchQuery}</strong>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='h-screen flex flex-col gap-5 justify-center items-center'>
                            <NoData icon={<Search size={30} />}>
                                <h3 className='text-normal'>No Brokers Found</h3>
                            </NoData>
                            <SettingsBtn
                                isBorder={false}
                                bgColor="bg-customPrimary"
                                textColor="text-white"
                                width="md:w-2/12"
                                onClick={handleOpenModal}
                            >
                                Create Broker Agent(s)
                            </SettingsBtn>
                        </div>
                    )}
                </>
            )}

            <CustomModal
                width="w-[300px] md:w-4/12"
                icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
                iconRemove={<AiOutlineClose onClick={handleCloseModal} className="w-6 h-6 text-[#667085]" />}
                title="Create Brokerage agent(s)"
                description=""
                bgColor="bg-white"
                textColor="text-black"
                closeModal={handleCloseModal}
                isModalOpen={isModalOpen}
            >
                <CsvFileUpload handleCloseModal={handleCloseModal}/>
            </CustomModal>
            <CustomModal
                width="w-[300px] md:w-4/12"
                icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
                iconRemove={<AiOutlineClose onClick={handleCloseActivateBrokerModal} className="w-6 h-6 text-[#667085]" />}
                title="Activate Agent(s)"
                description="You are about to activate the following agents, you will also be required to pay a fee to perfom this action."
                bgColor="bg-white"
                textColor="text-black"
                closeModal={handleCloseActivateBrokerModal}
                isModalOpen={isActivateBrokerModalOpen}
            >
                <ul className='list-disc'>
                    {
                        selectedBrokers?.map((broker: any, i: number)=>(
                            <li key={i}>{broker?.last_name} {broker?.first_name}</li>
                        ))
                    }
                </ul>
                <div className='flex justify-between items-center border-t py-5'>
                    <SettingsBtn
                        isBorder={false}
                        bgColor="bg-whiteSmoke"
                        textColor="text-[#000]"
                        width="md:w-4/12"
                        onClick={handleCloseActivateBrokerModal}
                    >
                    Cancel
                    </SettingsBtn>
                    <SettingsBtn
                        isBorder={false}
                        bgColor="bg-customPrimary"
                        textColor="text-white"
                        width="md:w-4/12"
                        onClick={handleActivateAgent}
                    >          
                    { isLoading ? <SimpleSpinner size={'sm'}/> : 'Activate Agent' }
                    </SettingsBtn>
                </div>
            </CustomModal>
        </div>
    );
};

export default ListBrokerAgents;
