import React from 'react'

const TermsAndConditions = () => {
  return (
    // <div className='h-screen w-3/4 m-auto grid grid-cols-1 border'>
    //     <iframe 
    //         src="https://showingxchange-public-access.s3.us-east-2.amazonaws.com/docs/termsofservice.html"
    //         width="100%" 
    //         height="100%"
    //         title='legal'
    //     ></iframe>
    // </div>
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg font-primary my-5">
      <h1 className="text-sm font-bold mb-4">Terms of Service- ShowingXchange</h1>
      <p className="mb-4"><strong>Effective Date:</strong> 7/23/2024</p>
      
      <h2 className="text-sm font-bold mb-2">Introduction</h2>
      <p className="mb-4">
      Welcome to ShowingXchange (“we”, “our”, “us”). These Terms of Service (“Terms”) govern your use of our mobile application (“App”) and any related services (collectively, the “Services”). By accessing or using our App, you agree to these Terms. If you do not agree with these Terms, please do not use our App.
      </p>
      <h2 className="text-sm font-bold mb-2">2. Acceptance of Terms</h2>
      <p className="mb-2">By downloading, installing, or using our App, you:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>Acknowledge that you have read, understood, and agree to be bound by these Terms.</li>
        <li>Represent that you are at least 18 years old and have the legal capacity to enter into</li>
      </ul>
      <h2 className="text-sm font-bold mb-2">3. Changes to Terms</h2>
      <p className="mb-2">We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the App after any changes constitutes your acceptance of the revised Terms. You are advised to review these Terms periodically.</p>

      <h2 className="text-sm font-bold mb-2">4. Registration and Account</h2>
      <h3 className="text-sm font-bold mb-2">4.1 Account Creation</h3>
      <p className="mb-2">
      To use certain features of our App, you may need to create an account. You agree to:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li> Provide accurate, current, and complete information during registration.</li>
        <li> Maintain and promptly update your account information to keep it accurate, current, and complete.</li>
        <li> Maintain the security of your account and accept all risks of unauthorized access.</li>
        <li> Immediately notify us of any unauthorized use of your account or other breaches of security</li>
        <li> Receive SMS/Text messages on the phone number you provide, which will contain the One Time Passcodes (OTP) to validate your phone number ownership.</li>
      </ul>

      <h2 className="text-sm font-bold mb-2">4.2 Account Responsibilities</h2>
      <p>You are responsible for safeguarding your password and for any activities or actions under your account. We are not liable for any loss or damage arising from your failure to comply with these requirements.</p>
      <h2 className="text-sm font-bold mb-2">5. Use of the App</h2>
      <h2 className="text-sm font-bold mb-2">5.1  License</h2>
      <p className="mb-2">We grant you a limited, non-exclusive, non-transferable, and revocable license to use our App for personal, non-commercial use, subject to these Terms.</p>
      <h2 className="text-sm font-bold mb-2">5.2  Prohibited Conduct</h2>
      <p>You agree not to:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>Violate any local, state, national, or international law.</li>
        <li>Infringe the rights of any third party, including intellectual property, privacy, or contractual rights</li>
        <li>Transmit any material that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.</li>
        <li>Interfere with or disrupt the App or servers or networks connected to the App.</li>
        <li>Use any data mining, robots, or similar data gathering or extraction methods.</li>
        <li> Use the App for any illegal or unauthorized purpose.</li>
      </ul>
      <h2 className="text-sm font-bold mb-2">5.3 User Content</h2>
      <p>You may post, upload, or otherwise make available content, including text, images, and other materials (“User Content”). You retain all rights in, and are solely responsible for, the User Content you make available through the App. By making User Content available through the App, you grant us a non-exclusive, transferable, sublicensable, worldwide, royalty-free license to use, copy, modify, create derivative works based on, distribute, publicly display, and publicly perform your User Content in connection with operating and providing the Services.</p>
   
    

      <h2 className="text-sm font-bold mb-2">6. Real Estate Property Showings</h2>
      <h3 className="text-sm font-bold mb-2">6.1 Scheduling Showings</h3>
      <p className="mb-4">
      Our App allows users to schedule property showings. You agree to provide accurate and complete information when scheduling showings and to honor your commitments to attend scheduled showings.
      </p>
      <h3 className="text-sm font-bold mb-2">6.2 Showings by Agents</h3>
      <p className="mb-4">
      Real estate agents may use our App to accept and fulfill showing requests. Agents are responsible for ensuring that they have the necessary qualifications and licensing to conduct property showings.
      </p>
      <h3 className="text-sm font-bold mb-2">6.3  Cancellation Policy</h3>
      <p className="mb-4">
      If you need to cancel a showing, please do so in accordance with our cancellation policy, as specified in the App. Failure to adhere to the cancellation policy may result in penalties or restrictions on your use of the App.
      </p>

      <h2 className="text-sm font-bold mb-2">7. Real Estate Transactions</h2>
   
      <h2 className="text-sm font-bold mb-2">7.1 Listings</h2>
      <p className="mb-4">
      Users may post real estate listings on our App. We do not verify the accuracy, completeness, or legality of such listings and are not responsible for any errors or omissions.
      </p>
      <h2 className="text-sm font-bold mb-2">7.2 Transactions</h2>
      <p className="mb-4">
      All real estate transactions conducted through the App are solely between the parties involved. We are not a party to, and will not be responsible for, any real estate transactions or disputes between users. You agree to comply with all applicable laws and regulations when engaging in real estate transactions through the App.
      </p>

      <h2 className="text-sm font-bold mb-2">8. Payments and Fees</h2>
      <p className="mb-4">
        Our App is not intended for use by children under the age of 13. We do not knowingly collect
        personal information from children under 13. If we become aware that we have collected personal
        information from a child under age 13, we will take steps to delete such information from our records.
      </p>
      <h2 className="text-sm font-bold mb-2">8.1 Fees</h2>
      <p className="mb-4">
      Certain features of our App may require payment. You agree to pay all applicable fees as described in the App. We reserve the right to change our fees at any time.
      </p>
      <h2 className="text-sm font-bold mb-2">8.2 Payments</h2>

      <h2 className="text-sm font-bold mb-2">9. Intellectual Property</h2>
      <h2 className="text-sm font-bold mb-2">9.1 Ownership</h2>
      <p className="mb-4">
      All content and materials available on the App, including text, graphics, logos, and images, are the intellectual property of ShowingXchange and are protected by copyright and trademark laws. You are granted a limited license only for the purpose of viewing and using the material contained on the App.
      </p>
      <h2 className="text-sm font-bold mb-2">9.2 Trademarks</h2>
      <p className="mb-4">
      ShowingXchange, and related names, logos, and product names are trademarks or registered trademarks of ShowingXchange and Plan Infinity Inc.. You may not use these trademarks without our prior written consent.
      </p>

      <h2 className="text-sm font-bold mb-2">10. Privacy</h2>
      <p className="mb-4">
      Your use of the App is also governed by our Privacy Policy, which describes how we collect, use, and share your personal information. By using the App, you consent to our Privacy Policy.
      </p>

      <h2 className="text-sm font-bold mb-2">11. Disclaimers</h2>
      <h2 className="text-sm font-bold mb-2">11.1 No Warranty</h2>
      <p className="mb-4">
      The App and Services are provided “as is” and “as available” without any warranties of any kind, either express or implied. We do not warrant that the App will be uninterrupted or error-free.
      </p>
      <h2 className="text-sm font-bold mb-2">11.2 Limitation of Liability</h2>
      <p className="mb-4">
      To the fullest extent permitted by law, we are not liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the App or Services, even if we have been advised of the possibility of such damages.
      </p>
      <h2 className="text-sm font-bold mb-2">11.3 Indemnification</h2>
      <p className="mb-4">
      You agree to indemnify and hold harmless ShowingXchange, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or in any way connected with your use of the App, your User Content, or your violation of these Terms.
      </p>
     
    <h2 className="text-sm font-bold mb-2">13. Termination</h2>
    <p>
    We may terminate or suspend your account and access to the App at our sole discretion, without notice, for any reason, including if we believe you have violated these Terms. Upon termination, your right to use the App will immediately cease. <br />
    </p>
    <h2 className="text-sm font-bold mb-2">14. Governing Law</h2>
    <p>
    These Terms are governed by and construed in accordance with the laws of Florida/USA, without regard to its conflict of law principles. Any legal action or proceeding arising under these Terms shall be brought exclusively in the courts located in Florida, USA.
    </p>
    <h2 className="text-sm font-bold mb-2">15. Dispute Resolution</h2>
    <p>
    Any disputes arising from or relating to these Terms or your use of the App will be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration will be conducted in Tampa, Florida, and judgment on the arbitration award may be entered in any court having jurisdiction.
    </p>
    <h2 className="text-sm font-bold mb-2">16. Miscellaneous</h2>
    <h2 className="text-sm font-bold mb-2">16.1 Entire Agreements</h2>
    <p>
    These Terms constitute the entire agreement between you and ShowingXchange regarding your use of the App and supersede all prior agreements and understandings.
    </p>
    <h2 className="text-sm font-bold mb-2">16.2 Severability</h2>
    <p>
    If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
    </p>
    <h2 className="text-sm font-bold mb-2">16.3 Waiver</h2>
    <p>
    The failure to enforce any right or provision of these Terms will not constitute a waiver of such right or provision.
    </p>
    <h2 className="text-sm font-bold mb-2">16.4 Assignment</h2>
    <p>
    We may assign our rights and obligations under these Terms to any party without notice to you. You may not assign your rights or obligations under these Terms without our prior written consent.
    </p>
    <h2 className="text-sm font-bold mb-2">17 Contact Us</h2>
    <p>
    If you have any questions about these Terms or the App, please contact us at: <br />

    <strong>ShowingXchange</strong> <br />
    <strong>10006 Cross Creek Blvd Ste 148, Tampa, Florida, 33647</strong> <br />
    <a href="mailto:support@showingxchange.com" className="text-blue-600 hover:underline">support@showingxchange.com</a> <br />
    <strong>904-569-7483</strong>
    </p>
  </div>
  )
}

export default TermsAndConditions