
import tokenService from '../../services/token.service';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../components/button';
import { Link } from 'react-router-dom';
import { pageURL } from '../../components/navigation/pagesURL';

const ApplicationCheck = () => {
    const navigateTo = useNavigate();
    const { user } = tokenService.getUser() || {};
    const adminType = 
    user.is_operation===true ? 'operation' 
    : user.is_billing===true ? 'billing'
    : user.is_managing_broker===true ? 'is_managing_broker' : null;
    console.log(adminType, 'adminType');

    const handleRedirect = ()=>{
        const url = `${pageURL.createAdminApplication}?type=${adminType}`;
        navigateTo(url);
    }

  return (
    <div className='h-screen flex flex-col justify-center items-center px-5 md:px-5 font-primary'>
        {
            adminType === 'is_managing_broker' ? (
                <>
                 <p className='my-5 text-sm'>
                    The Application will be reviewed by our team. <br />
                     Application update notifications will be sent to your email <span className='text-customPrimary font-bold'>{user.email}</span> <br />
                     Thank you!
                </p>
                </>
            ): (
                <div className='md:w-4/12 font-primary'>
                    <div>
                        <p className=' text-sm'>
                            You have successfully logged in. The next step is to submit a short application below.
                        </p>
                        <p className='my-5 text-sm'>
                            The Application will be reviewed by our team. Application update notifications will be sent to your email <span className='text-customPrimary font-bold'>{user.email}</span>
                        </p>
                    </div>
                    <CustomButton 
                    isBorder={false}
                    bgColor='customPrimary'
                    hoverBgColor='customPrimary'
                    textColor='white'
                    onClick={handleRedirect}>Start Application</CustomButton>
                </div>
            )
        }
    </div>
  )
}

export default ApplicationCheck