import React from 'react'
import { useNavigate } from "react-router-dom";


interface IStatsCardBProps {
    title: string;
    value: string;
    icon: React.ReactNode;
    redirectFn: ()=> void;
  }
  

const StatsCardB = ({ title, value, icon, redirectFn }: IStatsCardBProps ) => {


  return (
    <div className="bg-white shadow-md p-4 rounded-lg cursor-pointer" onClick={redirectFn}>
    <p className="text-gray-500 text-sm mb-1">{title}</p>
    <div className="flex items-center gap-3">
      <span className="text-sm">{icon}</span>
      <span className="text-sm font-normal text-navy-900">{value}</span>
    </div>
  </div>
  )
}

export default StatsCardB