
interface  InoData {
    children: React.ReactNode,
    icon:  React.ReactNode
}

const NoData = ({ children, icon }:InoData) => {
    return (
      <div className='w-full flex flex-col justify-center items-center bg-white '>
          <div className="h-48 w-48 flex flex-col justify-center items-center bg-slate-50 rounded-full shadow-sm">
            <span>{icon}</span>
            <div className='py-3 text-center'>{ children }</div>
          </div>
      </div>
    )
  }
  
  export default NoData;