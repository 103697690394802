import React, { useState } from 'react';
import { Formik, Form } from "formik";
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { useAuthPostRequest } from '../../hooks/post/useAuthPostRequest';
import { Button } from '../../components/button';
import { AddPhoneNumberSchema } from '../../utils/yupSchemas';
import { countriesFlagPhoneExts } from '../../utils/countriesFlagPhoneExts';
import { PhoneInput } from '../../components/forms/inputs';
import SimpleSpinner from '../../components/loaders/SimpleSpinner';
import { pageURL } from '../../components/navigation/pagesURL';




const AddPhoneNumber = () => {
    const [searchCountryQuery, setCountrySearchQuery] = useState<string>('');
    const [showCountrySearch, setShowCountrySearch] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState({name: 'United States', flag: '🇺🇸', code: 'US', dial_code: '+1'});
    const authPostRequest = useAuthPostRequest();
    const navigateTo = useNavigate();
  
    const handleSelectCountry = (country:any)=>{
      setSelectedCountry(country)
    }
  
    const handleToggleCountrySearch = ()=>{
      setShowCountrySearch(!showCountrySearch) 
    }
  
  
    const handleCountrySearchQuery = (e:any)=>{
      setCountrySearchQuery(e.target.value);
      }

  
      const filterdCountries = countriesFlagPhoneExts && countriesFlagPhoneExts.filter((country)=>{
        const result = `${country?.name} ${country?.dial_code}`;
        return result.toLocaleLowerCase().includes(searchCountryQuery.toLocaleLowerCase()) 
    })
  
     

  return (
    <div className='h-screen flex justify-center items-center font-primary'>
        <div className='md:w-4/12'>
            <Formik
                initialValues={{ phone_number:"", }}
                validationSchema={AddPhoneNumberSchema}
            
                onSubmit={async (values, { setSubmitting }) => {
                const payload = {
                    phone_number: values.phone_number,
                    country_code: selectedCountry.dial_code,
                }
                try {
                    const { status, data, error } = await authPostRequest(payload, 'add-phone-social-login');
                    console.log(status)
                    console.log(data)
                    if (status === 201) {
                    toast.success(data?.message);
                        navigateTo(pageURL.verifyPhoneNumber)
                    }
                    if(status !==201){
                    toast.error(error)
                    }
                } catch (error:any) {
                    return error
                }
                setSubmitting(false);
                }}
            >
                {({ isSubmitting }) => (
                <Form>
                <div>
                    <>
                    <h3 className='text-sm font-normal mb-1 ml-1'>Enter Your Phone Number</h3>
                    <div className='flex items-center relative'>
                        <div className='w-10/12 md:w-3/12'>
                            <div className='p-3 flex items-center gap-3 border cursor-pointer text-sm' onClick={handleToggleCountrySearch}>
                                <h3>{selectedCountry.flag}</h3>
                                <span>{selectedCountry.dial_code}</span>
                            </div>
                            <div className=''>
                                {
                                    showCountrySearch && (
                                        <div className='w-full h-64 p-5 shadow-lg mt-10  absolute top-10 left-0 bg-white z-10 overflow-y-scroll'>
                                            <input 
                                                type="search"
                                                className='w-full rounded-md px-5 py-3 mt-2 outline-none border'
                                                placeholder='search country code'
                                                value={searchCountryQuery}
                                                onChange={handleCountrySearchQuery}
                                            />
                                            <ul className=''>
                                                {
                                                    filterdCountries.map((country, i)=>(
                                                        <li className='flex items-center gap-3 border-b py-2 cursor-pointer' key={i} onClick={()=>{
                                                            handleSelectCountry(country)
                                                            handleToggleCountrySearch()
                                                        }}>
                                                            <span>{country.flag}</span><h3>{country.name}({country.dial_code})</h3>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <PhoneInput
                        name='phone_number'
                        icon={undefined}
                        type='number'
                        placeholder='user phone number'
                        />
                    </div>
                    </>
                </div>
                {
                    isSubmitting ? 
                    <div className='flex justify-center items-center py-5'><SimpleSpinner size={'lg'}/></div> :
                    (
                    <Button  
                    disabled={isSubmitting}
                    isBorder={false}
                    >
                      {isSubmitting ? <SimpleSpinner size='sm' /> : <span>Verify Phone Number</span>}
                    </Button>
                    )
                }
                </Form>
                )}
            </Formik>
        </div>
    </div>
  )
}

export default AddPhoneNumber