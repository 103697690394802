import React, { useState, useEffect, ChangeEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import coreClient from '../../services/coreApi';
import { pageURL } from '../../components/navigation/pagesURL';
import { CustomButton } from '../../components/button';
import { SimpleSpinner } from '../../components/loaders';


interface OperationFormData {
    first_name: string;
    last_name: string;
    gender: string;
    date_of_birth: string;
    drivers_licence_number: string;
    issuing_state: string;
    issue_date: string;
    expiring_date: string;
    upload_picture: File | null;
    front_drivers_licence_picture: File | null;
    back_drivers_licence_picture: File | null;
    operation_address1: string;
    operation_address2: string;
    operation_city: string;
    operation_state: string;
    zip_code: string;
    operation_country: string;
}

const CreateAdminApplication: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const adminType = queryParams.get('type');

    const [operationFormData, setOperationFormData] = useState<OperationFormData>({
        first_name: "",
        last_name: "",
        gender: "Male",
        date_of_birth: "1996-09-21",
        drivers_licence_number: "09080-909",
        issuing_state: "jos",
        issue_date: "2016-09-21",
        expiring_date: "2024-09-22",
        upload_picture: null,
        front_drivers_licence_picture: null,
        back_drivers_licence_picture: null,
        operation_address1: 'test address',
        operation_address2: 'test address',
        operation_city: 'City Name',
        operation_state: 'Benue',
        zip_code: '900108',
        operation_country: 'Nigeria'
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setOperationFormData({ ...operationFormData, [name]: value });
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, files } = e.target;
        if (files && files.length > 0) {
            setOperationFormData({ ...operationFormData, [name]: files[0] });
        }
    };

    const handleSubmitForm = async () => {
        const operationPayoad = new FormData();
        for (const key in operationFormData) {
            if (operationFormData[key as keyof OperationFormData] instanceof File) {
                operationPayoad.append(key, operationFormData[key as keyof OperationFormData] as File);
            } else {
                operationPayoad.append(key, operationFormData[key as keyof OperationFormData] as string);
            }
        }

        setLoading(true)
        try {
            const { status, data } = await coreClient.post('/operation-application/', operationPayoad, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            });
            if(status === 201){
                console.log(data, 'data');
                toast.success(data.message)
                navigate(pageURL.signIn);
            }
        } catch (error:any) {
            if (error.response && error.response.data && error.response.data) {
                toast.error(`${error.response.data.detail}`);
              } else if (error.request) {
                  toast.error('No response from server. Please try again later.');
              } else {
                  toast.error(`${error.message}`);
              }
            setLoading(false);
        }finally{
            setLoading(false)
        }
    };

    useEffect(() => {
        if (!adminType) {
            navigate('/');
        }
    }, [adminType, navigate]);

    if (!adminType) {
        return null;
    }

    return (
        <div className='md:h-screen flex flex-col md:flex-row justify-center items-center bg-whiteSmoke'>
            <div className='md:w-7/12 grid grid-cols-1 md:grid-cols-3 gap-3 font-primary bg-white p-10 rounded-md'>
                {['first_name', 'last_name', 'gender', 'date_of_birth', 'operation_address1', 'drivers_licence_number', 'operation_address2', 'operation_city', 'operation_state', 'zip_code', 'operation_country', 'issuing_state', 'issue_date', 'expiring_date'].map((field, idx) => (
                    <div className='flex flex-col my-2' key={idx}>
                        <label htmlFor={field} className='capitalize'>{field.replaceAll('_', ' ')}</label>
                        <input
                            type="text"
                            placeholder={field.replaceAll('_', ' ')}
                            value={operationFormData[field as keyof OperationFormData] as string}
                            name={field}
                            onChange={handleChange}
                            className='w-full outline-0 py-2 border px-2 rounded-md capitalize'
                        />
                    </div>
                ))}
                {['upload_picture', 'front_drivers_licence_picture', 'back_drivers_licence_picture'].map((field, idx) => (
                    <div className='flex flex-col my-5' key={idx}>
                        <label htmlFor={field} className='capitalize'>{field.replaceAll('_', ' ')}</label>
                        <input
                            type="file"
                            name={field}
                            accept='application/pdf, image/*'
                            onChange={handleFileChange}
                            className='w-full border'
                        />
                    </div>
                ))}
                {/* <button onClick={handleSubmitForm} className='my-5 bg-blue-500 text-white py-2 px-4 rounded'>Submit</button> */}
                <div>
                    <CustomButton 
                        isBorder={false}
                        bgColor='customPrimary'
                        hoverBgColor='customPrimary'
                        textColor='white'
                        onClick={handleSubmitForm}>
                        {
                            loading ? <SimpleSpinner size='xs' /> : <span>Submit Application</span>
                        }
                    </CustomButton>
                </div>
            </div>
        </div>
    );
}

export default CreateAdminApplication;
